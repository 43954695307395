import React, { useEffect, useState } from 'react'
import HalfLoader from '../../HalfLoader.tsx';
import Data_Fetch_Api from '../../contexts/Data_Fetch_Api.tsx';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const baseUrl = process.env.REACT_APP_BACKEND_URL;
function StudentAndProfessorPermissionChange() {

    const navigate = useNavigate();
    const [IsloadingStudentDataLoading, SetIsloadingStudentDataLoading] = useState(false);
    const [StudentData, SetStudentData] = useState([]);
    const [IsloadingProfessorDataLoading, ErrorIdRequestVerified, getdataIdVerifiedRequest] = Data_Fetch_Api('/api/v1/admin/auth/event/registration/payment/verified/data');
    const [Role, SetRole] = useState<string[]>([]);
    const [ClubNameAndClubId, SetClubNameAndClubId] = useState<string[]>([]);
    const [IsLoadingRequest, setIsLoadingRequest] = useState(false);
    const [ClubManagerRole, SetClubManagerRole] = useState(3);
    const StudentDataFetch = async () => {
        SetIsloadingStudentDataLoading(true);
        try {
            const res = await fetch(baseUrl + '/api/v1/admin/auth/student/data/', {
                method: 'GET',
                credentials: 'include'
            })
            if (res.status === 200) {
                const data = await res.json();
                SetRole([]);
                data?.data?.forEach((StudentDataEach) => {
                    SetRole(prev => [...prev, StudentDataEach.participant_Roll])
                })
                SetStudentData(data);
                SetIsloadingStudentDataLoading(false);
            } else {

                SetIsloadingStudentDataLoading(false);
            }

        } catch (error) {
            SetIsloadingStudentDataLoading(false);
            toast.error("Some technical issue");
        }

    }
    const ClubNameClubIdFind = async () => {
        try {
            setIsLoadingRequest(true)
            const res = await fetch(baseUrl + '/api/club/name/club-id/', {
                method: "GET",
                credentials: 'include'
            })
            const data = await res.json();
            SetClubNameAndClubId(data.data);
            setIsLoadingRequest(false)
        } catch (error) {
            console.log("Some technical issue");
            setIsLoadingRequest(false)

        }

    }

    const SetRoleData = async (event, index, id, techk_Shitiz_Id) => {
        const conformation = window.confirm("Are you sure to change the student role");
        if (conformation) {
            const roleData = event.target.value;
            const role_change = document.querySelector(`#role_change${index}`)



            if (Number(roleData) === 3 || Number(roleData) === 4) {
                SetClubManagerRole(roleData);
                if (role_change) {
                    if (role_change.classList.contains('hidden')) {
                        role_change.classList.remove('hidden')
                    } else {
                        role_change.classList.add('hidden')
                    }
                }
            } else {
                try {
                    SetIsloadingStudentDataLoading(true);
                    if (Number(roleData) || Number(roleData) === 0) {
                        const res = await fetch(`${baseUrl}/api/v1/admin/auth/student/role/change/${id}`, {
                            method: 'PATCH',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ role: roleData, techk_Shitiz_Id }),
                            credentials: 'include',
                        })
                        const data = await res.json();
                        if (res.status === 200) {
                            StudentDataFetch();
                            toast.success(data.message);
                            SetIsloadingStudentDataLoading(false);
                        }
                        else if (res.status === 400) {
                            toast.error(data.message);
                            SetIsloadingStudentDataLoading(false);
                        }
                        else if (res.status === 402) {
                            toast.error(data.message);
                            SetIsloadingStudentDataLoading(false);
                        }
                        else if (res.status === 401) {
                            toast.error(data.message);
                            SetIsloadingStudentDataLoading(false);
                        } else {
                            toast.error("Some technical issue");
                            SetIsloadingStudentDataLoading(false);
                        }

                    } else {
                        const clubdata = JSON.parse(roleData);
                        const res = await fetch(`${baseUrl}/api/v1/admin/auth/student/role/change/${id}`, {
                            method: 'PATCH',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ techk_Shitiz_Id, role: ClubManagerRole, participant_Club_Roll_id: clubdata.club_Id, participant_Club_Roll_name: clubdata.clubname }),
                            credentials: 'include',
                        })
                        const data = await res.json();
                        if (res.status === 200) {
                            StudentDataFetch();
                            toast.success(data.message);
                            SetIsloadingStudentDataLoading(false);
                        }
                        else if (res.status === 400) {
                            toast.error(data.message);
                            SetIsloadingStudentDataLoading(false);
                        }
                        else if (res.status === 401) {
                            toast.error(data.message);
                            SetIsloadingStudentDataLoading(false);
                        }
                        else if (res.status === 402) {
                            toast.error(data.message);
                            SetIsloadingStudentDataLoading(false);
                        } else {
                            toast.error("Some technical issue");
                            SetIsloadingStudentDataLoading(false);
                        }
                    }

                } catch (error) {
                    toast.error("Some technical issue");
                    SetIsloadingStudentDataLoading(false);
                }
            }
        }

    }
    useEffect(() => {
        StudentDataFetch();
        ClubNameClubIdFind();
    }, [])
    if (IsloadingStudentDataLoading || IsloadingProfessorDataLoading || IsLoadingRequest) {
        return <HalfLoader message="Loading.." />
    }
    if (ErrorIdRequestVerified) {
        return <h1>Some thing want wrong</h1>
    }
    const TechKshitizIdDeleteData = async (id) => {
        const verifyDeleteData = window.confirm("Are you sure to delete techkshitiz id")
        try {
            SetIsloadingStudentDataLoading(true);
            if (verifyDeleteData) {
                const res = await fetch(`${baseUrl}/api/v1/admin/auth/student/techkshitiz/id/deleted/${id}`, {
                    method: 'DELETE',
                    credentials: 'include',
                })
                if (res.status === 200) {
                    SetIsloadingStudentDataLoading(false);
                    StudentDataFetch();
                    toast.success("TechKshitiz Id Deleted sucessfully");
                } else {
                    SetIsloadingStudentDataLoading(false);
                    toast.error("Some technical issue");

                }
            }


        } catch (error) {
            toast.error("Some technical issue");
            SetIsloadingStudentDataLoading(false);
        }
    }


    return (
        <>
            <div id='user_Id_admin_profile' className='w-[100%] h-[100%] rounded-[4px]  bg-[#150f15c4] text-[#fff] overflow-auto '>
                <div className='w-[auto] h-[100%]  p-[20px]'>
                    <table className=' w-[auto]   border-[1px]  border-[#747a86] border-collapse ' >
                        <tr className='border-[1px]   border-[#747a86] '>
                            <th className='p-[10px] text-left'>Sno</th>
                            <th className='p-[10px] text-left'>Full Name</th>
                            <th className='p-[10px] text-left'>TechKshitiz Id</th>
                            <th className='p-[10px] text-left'>Email Id</th>
                            <th className='p-[10px] text-left'>Phone Number</th>
                            <th className='p-[10px] text-left'>Departement Name</th>
                            <th className='p-[10px] text-left'>Current Year</th>
                            <th className='p-[10px] text-left'>Admission Year</th>
                            <th className='p-[10px] text-left'>Registration Number</th>
                            <th className='p-[10px] text-left'>College Name</th>
                            <th className='p-[10px] text-left'>Date Of Birth</th>
                            <th className='p-[10px] text-left'>Dietary Restrictions</th>
                            <th className='p-[10px] text-left'>Emergency Contact Name</th>
                            <th className='p-[10px] text-left'>Emergency Contact Phone Number</th>
                            <th className='p-[10px] text-left'>Emergency Contact Relationship</th>
                            <th className='p-[10px] text-left'>Event Fees</th>
                            <th className='p-[10px] text-left'>Event Participation History</th>
                            <th className='p-[10px] text-left'>Gender</th>
                            <th className='p-[10px] text-left'>Housing Accommodations</th>
                            <th className='p-[10px] text-left'>Previous Event Participation History</th>
                            <th className='p-[10px] text-left'>Residential Address</th>
                            <th className='p-[10px] text-left'>Special Accommodations</th>
                            <th className='p-[10px] text-left'>T-Shirt Size</th>
                            <th className='p-[10px] text-left'>Transportation Arrangements</th>
                            <th className='p-[10px] text-left'>Volunteering Interest</th>
                            <th className='p-[10px] text-left'>Role</th>
                            <th className='p-[10px] text-left'>Change Role</th>
                            <th className='p-[10px] text-left'>Delete Id</th>

                        </tr>
                        {
                            Object(StudentData).data ? (Object(StudentData).data[0] ? (
                                Object(StudentData).data.map((RequestData, index) => (
                                    <tr key={index} className=' border-[1px]     border-[#747a86]'>
                                        <td className='p-[10px] text-left  '>
                                            <div className='w-[50px]  truncate capitalize flex justify-center items-center'>{index + 1}</div>
                                        </td>
                                        <td className='p-[10px] text-left  '>
                                            <div className='w-[200px]  truncate capitalize'>{RequestData.participant_Name}</div>
                                        </td>
                                        <td className='p-[10px] text-left  '>
                                            <div className='w-[300px]  truncate capitalize'>{RequestData.techk_Shitiz_Id}</div>
                                        </td>
                                        <td className='p-[10px] text-left  '>
                                            <div className='w-[300px]  truncate capitalize'>{RequestData.participant_Email}</div>
                                        </td>
                                        <td className='p-[10px]  text-left    '>
                                            <div className='w-[200px]  truncate capitalize'>{RequestData.participant_Phone}</div>
                                        </td>
                                        <td className='p-[10px] text-left  '>
                                            <div className='w-[400px]  truncate capitalize'>{RequestData.participant_Branch}</div>
                                        </td>
                                        <td className='p-[10px] text-left '><div className='w-[150px]  truncate capitalize'>{RequestData.participant_Year_of_Study}</div></td>
                                        <td className='p-[10px] text-left '><div className='w-[200px]  truncate capitalize'>{RequestData.participant_Admission_Year}</div></td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[200px]  truncate capitalize'>{RequestData.participant_Registration_Number}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[400px]  truncate capitalize'>{RequestData.participant_College_Name}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[100px]  truncate capitalize'>{RequestData.participant_Date_of_Birth}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[300px]  truncate capitalize'>{RequestData.participant_Dietary_Restrictions ? RequestData.participant_Dietary_Restrictions : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[300px]  truncate capitalize'>{RequestData.participant_Emergency_Contact_Name ? RequestData.participant_Emergency_Contact_Name : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[300px]  truncate capitalize'>{RequestData.participant_Emergency_Contact_Phone_Number ? RequestData.participant_Emergency_Contact_Phone_Number : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[300px]  truncate capitalize'>{RequestData.participant_Emergency_Contact_Relationship ? RequestData.participant_Emergency_Contact_Relationship : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[300px]  truncate capitalize'>{RequestData.participant_Event_Fees ? RequestData.participant_Event_Fees : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[300px]  truncate capitalize'>{RequestData.participant_Event_Participation_History ? RequestData.participant_Event_Participation_History : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[150px]  truncate capitalize'>{RequestData.participant_Gender ? RequestData.participant_Gender : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[250px]  truncate capitalize'>{RequestData.participant_Housing_Accommodations ? RequestData.participant_Housing_Accommodations : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[300px]  truncate capitalize'>{RequestData.participant_Previous_Event_Participation_History ? RequestData.participant_Previous_Event_Participation_History : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[200px]  truncate capitalize'>{RequestData.participant_Residential_Address ? RequestData.participant_Residential_Address : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[200px]  truncate capitalize'>{RequestData.participant_Special_Accommodations ? RequestData.participant_Special_Accommodations : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[200px]  truncate capitalize'>{RequestData.participant_T_Shirt_Size ? RequestData.participant_T_Shirt_Size : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[250px]  truncate capitalize'>{RequestData.participant_Transportation_Arrangements ? RequestData.participant_Transportation_Arrangements : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[200px]  truncate capitalize'>{RequestData.participant_Volunteering_Interest ? RequestData.participant_Volunteering_Interest : "Not Available"}</div>
                                        </td>



                                        <td className='p-[10px] text-left'>
                                            <div className='w-[300px]  truncate capitalize'>{Number(RequestData.participant_Roll) === 0 ? "Intra College Student" : RequestData.participant_Roll === 1 ? "Student" : Number(RequestData.participant_Roll) === 2 ? "Professor" : Number(RequestData.participant_Roll) === 3 ? "Club Manager" : Number(RequestData.participant_Roll) === 4 ? "Campus Ambassador" : "Not Available"}
                                                <span className=' ml-2'>{RequestData?.participant_Club_Roll_id}</span></div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[auto]  truncate capitalize p-[10px]  pt-0 pb-0  text-[#000]'>
                                                <select className='w-[250px]' name="role_change" value={Role[index]} onChange={(event) => { SetRoleData(event, index, RequestData._id, RequestData?.techk_Shitiz_Id) }} id="role_change">
                                                    <option value={0}>Intra College</option>
                                                    <option value={1}>Student</option>
                                                    <option value={2}>Professor</option>
                                                    <option value={3}>Club Manager</option>
                                                    <option value={4}>Campus Ambassador</option>
                                                </select>
                                                <select className={`w-[250px] ml-6 hidden`} name="role_change" onChange={(event) => { SetRoleData(event, index, RequestData._id, RequestData?.techk_Shitiz_Id) }} id={`role_change${index}`} required>
                                                    <option value="">Select Club Name</option>
                                                    {
                                                        ClubNameAndClubId?.map((data) => (
                                                            <option value={JSON.stringify(Object(data))}>{Object(data).clubname}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div onClick={() => { TechKshitizIdDeleteData(Object(RequestData)._id) }} className={`w-[200px] h-[50px] flex justify-center items-center cursor-pointer select-none rounded-[5px] text-[#fff] truncate capitalize bg-[#ff3737] text-[18px] `}>Delete Id</div>
                                        </td>

                                    </tr>

                                ))
                            ) : (
                                <>
                                </>

                            )) : (
                                <></>
                            )
                        }
                    </table>
                </div>
            </div>
        </>
    )
}

export default StudentAndProfessorPermissionChange
