import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CatrasoAnimationLoading from "../CatrasoAnimationLoading.tsx";
const baseUrl = process.env.REACT_APP_BACKEND_URL;
const AdminLoginVerification = () => {
    const navigate = useNavigate();
    const [getdata, setdata] = useState({ otp: "" });
    const [IsLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);

    const changedata = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setdata({ ...getdata, [name]: value });
    };
    const datasubmit = async (event) => {
        event.preventDefault();
        try {
            setIsLoadingRequest(true)
            const { otp } = getdata;

            const res = await fetch(baseUrl + "/api/admin/otp/verification/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'include',
                body: JSON.stringify({ otp }),
            });

            if (res.status === 200) {
                toast.success("Login Sucessfully");
                setIsLoadingRequest(false)
                navigate("/");
            } else if (res.status === 400) {
                toast.error("All field require");
                setIsLoadingRequest(false)
            } else if (res.status === 500) {
                toast.error("Some technical issue");
                setIsLoadingRequest(false)

            } else if (res.status === 401) {
                toast.error("Invalid Otp");
                setIsLoadingRequest(false)

            } else if (res.status === 403) {
                toast.error("Some technical issue");
                setIsLoadingRequest(false)

            }
        } catch (error) {
            setIsLoadingRequest(false)

        }
    };

    if (IsLoadingRequest) {
        return <CatrasoAnimationLoading />
    }
    return (
        <>
            <div className="w-[100%] h-[100vh] bg-[#161f2f] flex justify-center items-center">
                <div className=" w-[350px] bg-[#efefef] h-[400px] rounded-[10px]  border-[2px]">
                    <div className=" w-[100%] h-[50px] flex justify-center items-center  ">
                        <h1 className="text-[22px] font-[700]">Otp Verificaton</h1>
                    </div>
                    <form onSubmit={datasubmit} className=" w-[100%] h-[350px]   ">
                        <div className=" w-[100%] h-[50px] flex justify-center items-center  mt-4">
                            <input
                                type="text"
                                placeholder="Enter your otp : "
                                name="otp"
                                onChange={changedata}
                                value={getdata.otp}
                                required
                                maxLength={6}
                                className="p-[10px] border-[1px] rounded-[5px] outline-[#bb2470] w-[90%] h-[100%] "
                            />
                        </div>
                        <div className=" w-[100%] h-[100px] flex justify-center items-center mt-[20px]">
                            <button
                                type="submit"
                                className=" p-[10px] border-2 w-[150px] rounded-[25px] bg-[#2c76c1] text-[#fff] font-[700]"
                            >
                                Verify
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AdminLoginVerification;
