import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import HalfLoader from '../../HalfLoader.tsx';
const baseUrl = process.env.REACT_APP_BACKEND_URL;
function PermissionEventandForm() {
    const [permissionData, setPermissionData] = useState([])
    const [IsLoader, SetIsLoader] = useState(true);
    const [IsChangeLoader, SetIsChangeLoader] = useState(false);
    const allpermissionFetch = async () => {
        try {
            SetIsLoader(true);
            const res = await fetch(baseUrl + '/api/v1/admin/auth/permission', {
                method: "GET",
                credentials: 'include'
            })
            if (res.status === 200) {
                const data = await res.json();
                setPermissionData(data.data);
                SetIsLoader(false);
            } else {

                SetIsLoader(false);
            }
        } catch (error) {
            SetIsLoader(false);
            toast.error("Some technical issue");
        }
    }
    useEffect(() => {
        allpermissionFetch();
    }, [])

    const changechildPermissionDataUpdate = async (id, event, permisson_Page_Id) => {
        const permissionAllowOrNot = window.confirm("Are you sure to change permission");
        if (permissionAllowOrNot) {
            try {
                SetIsChangeLoader(true);
                const res = await fetch(`${baseUrl}/api/v1/admin/auth/child/permission/updates/${id}`, {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ permisson_Set_Page_On_Off: Object(event).target.checked, permisson_Page_Id }),
                    credentials: 'include'
                })
                if (res.status === 200) {
                    allpermissionFetch();
                    SetIsChangeLoader(false);
                    toast.success("permission setting changes");
                } else {
                    SetIsChangeLoader(false);
                    toast.error("Some technical issue");

                }
            } catch (error) {
                SetIsChangeLoader(false);
                toast.error("Some technical issue");
            }
        }
    }
    const changePermissionData = async (id, event) => {
        const permissionAllowOrNot = window.confirm("Are you sure to change permission");
        if (permissionAllowOrNot) {
            try {
                SetIsChangeLoader(true);
                const res = await fetch(`${baseUrl}/api/v1/admin/auth/permission/updates/${id}`, {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ permission_Allow: Object(event).target.checked }),
                    credentials: 'include'
                })
                if (res.status === 200) {
                    allpermissionFetch();
                    SetIsChangeLoader(false);
                    toast.success("permission setting changes");
                } else {
                    SetIsChangeLoader(false);

                }
            } catch (error) {
                SetIsChangeLoader(false);
                toast.error("Some technical issue");
            }
        }
    }
    if (IsLoader) {
        return <HalfLoader message="Loading..." />
    }
    if (IsChangeLoader) {
        return <HalfLoader message="Processing..." />
    }
    return (
        <>
            <section className='w-[100%] h-[100%] select-none'>

                {
                    permissionData.length > 0 ? (
                        permissionData.map((permissionDataFilter, index) => (
                            <div className='mb-8'>
                                <div key={index} className='  w-[100%] h-[50px] flex justify-between items-center border-b-2  border-[#bfc5c9] pl-6 pr-4'>
                                    <h1 className={`text-[20px] font-[600] capitalize  ${Object(permissionDataFilter)?.permission_Allow ? " text-[#221e1ef6]" : " text-[#221e1e88]"} `}>{Object(permissionDataFilter)?.permission_Type_Name} Permission</h1>
                                    <input type="checkbox" checked={Object(permissionDataFilter)?.permission_Allow} onChange={(e) => { changePermissionData(Object(permissionDataFilter)._id, e) }} name="contest_Permission" className=' hidden' id={`${Object(permissionDataFilter)?.permission_Type_Name}_Permission_off_on`} />
                                    {
                                        Object(permissionDataFilter)?.permission_Allow ? (
                                            <>
                                                <label htmlFor={`${Object(permissionDataFilter)?.permission_Type_Name}_Permission_off_on`} className={` ${Object(permissionDataFilter)?.permission_Allow ? " text-[#221e1ee7]" : " text-[#221e1e88]"} material-symbols-outlined text-[35px] h-[25px]    cursor-pointer`}>
                                                    toggle_on
                                                </label>
                                            </>
                                        ) : (
                                            <>
                                                <label htmlFor={`${Object(permissionDataFilter)?.permission_Type_Name}_Permission_off_on`} className={`${Object(permissionDataFilter)?.permission_Allow ? " text-[#221e1ee7]" : " text-[#221e1e88]"} material-symbols-outlined text-[35px] text - [#24cd54] cursor-pointer`}>
                                                    toggle_off
                                                </label >
                                            </>
                                        )
                                    }
                                </div >
                                <div>
                                    {
                                        Object(permissionDataFilter)?.permission.length > 0 ? (
                                            Object(permissionDataFilter)?.permission.map((permissionDataFilterChild, childIndex) => (
                                                <div key={childIndex} className=' w-[98%] ml-[2%] h-[50px] flex justify-between items-center border-b-2  border-[#e2dcdcca] pl-6 pr-4'>
                                                    <h1 className={`text-[20px] font-[600] capitalize ${Object(permissionDataFilterChild)?.permisson_Set_Page_On_Off ? " text-[#221e1ef6]" : " text-[#221e1e88]"}`}>{Object(permissionDataFilterChild)?.permisson_Page_Name} Permission</h1>
                                                    <input type="checkbox" checked={Object(permissionDataFilterChild)?.permisson_Set_Page_On_Off} onChange={(e) => { changechildPermissionDataUpdate(Object(permissionDataFilter)._id, e, Object(permissionDataFilterChild).permisson_Page_Id) }} name="contest_child_Permission" className=' hidden' id={`${String(Object(permissionDataFilterChild)?.permisson_Page_Name).replace(" ", "_")}_child_Permission_off_On`} />
                                                    {
                                                        Object(permissionDataFilterChild)?.permisson_Set_Page_On_Off ? (
                                                            <>
                                                                <label htmlFor={`${String(Object(permissionDataFilterChild)?.permisson_Page_Name).replace(" ", "_")}_child_Permission_off_On`} className={`material-symbols-outlined text-[35px] h-[25px] ${Object(permissionDataFilterChild)?.permisson_Set_Page_On_Off ? " text-[#221e1ee7]" : " text-[#221e1e88]"} cursor-pointer`}>
                                                                    toggle_on
                                                                </label>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <label htmlFor={`${String(Object(permissionDataFilterChild)?.permisson_Page_Name).replace(" ", "_")}_child_Permission_off_On`} className={`material-symbols-outlined text-[35px]   cursor-pointer ${Object(permissionDataFilterChild)?.permisson_Set_Page_On_Off ? " text-[#221e1ee7]" : " text-[#221e1e88]"}`}>
                                                                    toggle_off
                                                                </label >
                                                            </>
                                                        )
                                                    }
                                                </div >

                                            ))
                                        ) : (
                                            <>
                                                <div className='w-full h-[100px] flex justify-center items-center '>
                                                    <h1 className=' text-[30px]'>{Object(permissionDataFilter)?.permission_Type_Name} child permission not available</h1>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>


                            </div>
                        ))
                    ) : (
                        <>
                            <div className='w-full h-[80vh] flex justify-center items-center '>
                                <h1 className=' text-[30px]'>Permission not available</h1>
                            </div>
                        </>
                    )
                }

            </section >
        </>
    )
}

export default PermissionEventandForm
