import React, { useEffect } from 'react'
import Admin_Panel_Box from './Admin_Panel_Box.tsx'
import Admin_PanelHeader from './Admin_PanelHeader.tsx'
import './Admin_Panel_Box.css'
import { useNavigate } from 'react-router-dom'
import Data_Fetch_Api from '../contexts/Data_Fetch_Api.tsx'
import CatrasoAnimationLoading from '../CatrasoAnimationLoading.tsx'

function AdminPanel() {
    const navigate = useNavigate();
    const [IsLoader, Error, data] = Data_Fetch_Api('/api/auth/admin/data')
    useEffect(() => {

        if (Error) {
            navigate('/admin/dashboard/login');
            return;
        }
    }, [data])
    if (!Object(data)?.adminid) {
        navigate('/admin/dashboard/login');
        return;
    }
    if (Number(Object(data)?.status) === 401) {
        navigate('/admin/dashboard/login');
        return;
    }
    if (IsLoader) {
        return <CatrasoAnimationLoading />
    }
    return (
        <>
            <Admin_PanelHeader />
            <Admin_Panel_Box />
        </>
    )
}

export default AdminPanel
