import React, { useState } from 'react'
import '../Form.css'
import { toast } from 'react-toastify';
import HalfLoader from '../HalfLoader.tsx';
const baseUrl = process.env.REACT_APP_BACKEND_URL;
function AdminPanelClubAdd() {
    const [getdata, setdata] = useState({ clubName: '', club_description: '' });
    const [IsLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
    const [Error, SetError] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<string>("")
    const [image, setImage] = useState(null);
    const [selectedDate, setSelectedDate] = useState("");
    const changedata = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setdata({ ...getdata, [name]: value });
    }

    const handleFileChange = (event) => {
        if (event.target.value) {

            const file = event.target.files[0];
            if (file.type === "image/jpeg") {
                if (file.size >= 512000) {
                    toast.error('File size below the 400kb');
                }
                else {
                    setImage(file);
                    if (file) {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            setSelectedFile(String(reader.result));
                        };
                        reader.readAsDataURL(file);
                    } else {
                        setSelectedFile("");
                    }

                }
            } else {
                setSelectedFile("");
                toast.error('Only Support image/jpeg format');

            }
        }
    };
    const handleDateChange = (event) => {
        const Date = event.target.value;
        setSelectedDate(Date);
    };
    const submidformdata = async (event) => {
        event.preventDefault();
        try {
            SetError(false)
            setIsLoadingRequest(true);
            const formData = new FormData();
            formData.append('contest_poster', Object(image));
            formData.append('clubdescription', getdata.club_description);
            formData.append('clubcreateddate', selectedDate);
            formData.append('clubname', getdata.clubName);
            const res = await fetch(baseUrl + '/api/club/create/api/data/', {
                method: 'POST',
                body: formData,
                credentials:'include'
            });
            if (res.status === 200) {
                setdata({ clubName: '', club_description: '' });
                toast.success("Club Added Sucessfully ");
                setSelectedFile("");
                setIsLoadingRequest(false);
            } else if (res.status === 401) {
                setIsLoadingRequest(false);
                setSelectedFile("");
                toast.error("Club Already Exist ");
            } else if (res.status === 500) {
                toast.error("Some technical issue");
                setIsLoadingRequest(false);
            } else if (res.status === 400) {
                toast.error("All field require");
                setIsLoadingRequest(false);
            } else {
                setIsLoadingRequest(false);
            }

        } catch (error) {
            SetError(true);
            toast.error("some technical issue");
            setIsLoadingRequest(false);
        }

    }
    if (IsLoadingRequest) {
        return <HalfLoader message="Processing.." />

    }
    if (Error) {
        return <h1>Something went wrong!!</h1>
    }
    return (
        <>
            <div className="container">
                <div className="title">Club Add</div>
                <hr />
                <div className="content">
                    <form onSubmit={submidformdata} encType='multipart/form-data'>
                        <div className="user-details">
                            <div className="input-box">
                                <span className="details">Club Name</span>
                                <input type="text" name='clubName' value={getdata.clubName} onChange={changedata} placeholder="Enter your Club Name" required />
                            </div>
                            <label htmlFor='create_Date' className="input-box">
                                <span className="details">Created Date</span>
                                <input type="date" name='create_Date' id='create_Date' onChange={handleDateChange} required />
                            </label>
                            <div className="input-box">
                                <span className="details">Club Description</span>
                                <textarea className='textarea' name='club_description' value={getdata.club_description} placeholder='Enter short description on this club' id='club_description' onChange={changedata} required />
                            </div>
                            <div className="input-box">
                                <span className="details">Club Poster</span>
                                <input type="file" name='club_Poster' id='club_Poster' onChange={handleFileChange} required />
                            </div>

                            <div className=' w-[300px] h-[250px] border-[2px] flex justify-center items-center border-[#000] rounded-[10px]'>
                                <img className='w-[100%] h-[100%] rounded-[10px]' src={selectedFile} alt='club_Poster' />
                            </div>


                        </div>
                        <div className="button-container">
                            <div className="button">
                                <input type="submit" value="Add Club" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AdminPanelClubAdd
