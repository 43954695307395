import React, { useState } from 'react';
import { toast } from 'react-toastify';
import HalfLoader from '../../HalfLoader.tsx';
const baseUrl = process.env.REACT_APP_BACKEND_URL

const AllPermissionGenrate = () => {
    const [IsLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
    const [getdata, setdata] = useState({ permission_Type: "", permission_page_name: "" });
    const [permission_set_page_name, setpermission_set_page_name] = useState("");
    const [Error, SetError] = useState<boolean>(false);

    const submidformdata = async (event) => {
        event.preventDefault();
        try {
            const { permission_Type, permission_page_name } = getdata;
            if (permission_Type && permission_page_name && permission_set_page_name) {
                SetError(false)
                setIsLoadingRequest(true);
                const res = await fetch(baseUrl + '/api/v1/admin/auth/permission/create', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({ permission_Type_Name: permission_Type, permisson_Page_Name: permission_page_name, permisson_Set_Page_Name: permission_set_page_name }),
                });
                if (res.status === 200) {
                    toast.success("Permission add sucessfully");
                    setdata({ permission_Type: "", permission_page_name: "" });
                    setpermission_set_page_name("");
                    setIsLoadingRequest(false);
                } else if (res.status === 401) {
                    toast.error("Permission page name already exist");
                    setIsLoadingRequest(false);
                } else if (res.status === 402) {
                    toast.error("Permission set page name already exist");
                    setIsLoadingRequest(false);
                } else if (res.status === 500) {
                    toast.error("Some technical issue");
                    setIsLoadingRequest(false);
                } else if (res.status === 400) {
                    toast.error("All field require");
                    setIsLoadingRequest(false);
                } else {
                    setIsLoadingRequest(false);
                }

            } else {
                setIsLoadingRequest(false);
                toast.error("All field require");
            }

        } catch (error) {
            SetError(true);
            toast.error("some technical issue");
            setIsLoadingRequest(false);
        }

    }
    const changedata = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setdata({ ...getdata, [name]: value });
    }
    if (IsLoadingRequest) {
        return <HalfLoader message="Processing.." />

    }
    if (Error) {
        return <h1>Something went wrong!!</h1>
    }
    return (
        <>
            <div className="container">
                <div className="title">Permission Add</div>
                <hr />
                <div className="content">
                    <form onSubmit={submidformdata} encType='multipart/form-data'>
                        <div className="user-details">
                            <div className="input-box">
                                <span className="details">Permission Type</span>
                                <select name='permission_Type' id='permission_Type' value={getdata.permission_Type} onChange={changedata} required >
                                    <option value="">Select a permission type</option>
                                    <option value="contest">Contest</option>
                                    <option value="events">Events</option>
                                    <option value="community">Community</option>
                                </select>
                            </div>

                            <div className="input-box">
                                <span className="details">Permission Page Name</span>
                                <input type="text" name='permission_page_name' value={getdata.permission_page_name} onChange={changedata} placeholder="Add permission page Name" required />
                            </div>
                            <div className="input-box">
                                <span className="details">Permission Set Page Name</span>
                                <input type="text" name='permission_set_page_name' value={permission_set_page_name} onChange={(e) => { setpermission_set_page_name(String(e.target.value).toLowerCase().trim()) }} placeholder="Add permission set page name only lowercase later not use special characters" required />
                            </div>

                        </div>
                        <div className="button-container">
                            <div className="button">
                                <input type="submit" value="Add Permission" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AllPermissionGenrate;
