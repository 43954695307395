import React, { useState } from 'react';
import { toast } from 'react-toastify';
import HalfLoader from '../../HalfLoader.tsx';
const baseUrl = process.env.REACT_APP_BACKEND_URL
const BranchNameRegister = () => {
    const [branchData, setBranchData] = useState<string>("");
    const [IsLoadingRequest, setIsLoadingRequest] = useState(false);
    const BranchNameRegisterSave = async (e) => {
        setIsLoadingRequest(true);
        e.preventDefault();
        try {
            if (branchData) {

                const res = await fetch(baseUrl + '/api/v1/admin/auth/branch/name/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ branch_Name: branchData }),
                    credentials:'include'
                })
                if (res.status === 200) {
                    toast.success('Branch added sucessfully');
                    setBranchData("");
                    setIsLoadingRequest(false);
                } else if (res.status === 402) {
                    toast.error('Branch name already exist');
                    setIsLoadingRequest(false);
                } else if (res.status === 400) {
                    setIsLoadingRequest(false)
                    toast.error('All field require');

                } else {
                    setIsLoadingRequest(false)
                    toast.error('Some technical issue');

                }
            } else {
                setIsLoadingRequest(false)
                toast.error('All field require');
            }
        } catch (error) {
            setIsLoadingRequest(false)
            toast.error('Some technical issue');

        }
    }
    if (IsLoadingRequest) {
        return <HalfLoader message="Processing..." />
    }
    return (
        <>
            <div className="container">
                <div className="title">Branch Name Add</div>
                <hr />
                <div className="content">
                    <form onSubmit={BranchNameRegisterSave}>
                        <div className="user-details">
                            <div className="input-box">
                                <span className="details">Branch Name</span>
                                <input type="text" name='branch_name' value={branchData} onChange={(e) => { setBranchData(e.target.value) }} placeholder="Enter the branch name" required />
                            </div>
                        </div>
                        <div className="button-container">
                            <div className="button">
                                <input type="submit" value="Add Name" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </>
    );
}

export default BranchNameRegister;
