import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
const ProjectSubmittions = () => {
  const [projectSubmittions, setProjectSubmittions] = useState([]);
  const [isProjectSubmittionsLoading, setIsProjectSubmittionsLoading] =
    useState(true);
  const [isTeamMemberDetailsPopupOpen, setIsTeamMemberDetailsPopupOpen] =
    useState(false);
  const [teamMemberDetails, setTeamMemberDetails] = useState([]);
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const fetchedProjectSubmittionsData = async () => {
    try {
      const res = await fetch(
        baseUrl + "/api/v1/admin/auth/online/project/submition/",
        {
          method: "GET",
          credentials: "include",
        }
      );
      const data = await res.json();
      if (data?.data?.length > 0) {
        const resultData = data.data;
        resultData?.map((item: any) => {
          const uint8Array = new Uint8Array(
            item.project_documentation_Pdf?.data.data
          );
          const blob = new Blob([uint8Array], { type: "application/pdf" });
          const dataUrl = URL.createObjectURL(blob);
          item.project_documentation_Pdf = dataUrl;
          return item;
        });
        setProjectSubmittions(data.data);
      } else {
        setProjectSubmittions([]);
      }
    } catch (eror) {
      console.log(eror);
    } finally {
      setIsProjectSubmittionsLoading(false);
    }
  };
  useEffect(() => {
    fetchedProjectSubmittionsData();
  }, []);

  useEffect(() => {
    if (teamMemberDetails?.length > 0) {
      setIsTeamMemberDetailsPopupOpen(true);
    }
  }, [teamMemberDetails]);

  console.log(teamMemberDetails);

  return (
    <>
      <div className="flex justify-end">
        <button
          onClick={() => {
            const ws = XLSX.utils.json_to_sheet(projectSubmittions);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            XLSX.writeFile(wb, "Project_Submittions.xlsx");
          }}
          className="bg-blue-500 text-white p-1 rounded"
        >
          Export All Details To Excel
        </button>
      </div>
      {isTeamMemberDetailsPopupOpen && (
        <div className="fixed top-0 left-0 w-full h-full z-50 bg-[#000000c4] flex items-center justify-center">
          <div className="w-1/2 h-1/2 bg-white rounded p-5 relative">
            <button
              onClick={() => setIsTeamMemberDetailsPopupOpen(false)}
              className="absolute top-2 right-2 text-red-500"
            >
              Close
            </button>
            <div className="overflow-auto mt-50">
              <table className="w-full border border-[#747a86] border-collapse">
                <thead>
                  <tr className="border border-[#747a86]">
                    <th className="p-2 text-left">Sno</th>
                    <th className="p-2 text-left">Name</th>
                    <th className="p-2 text-left">Email</th>
                    <th className="p-2 text-left">Phone</th>
                    <th className="p-2 text-left">Registration Number</th>
                    <th className="p-2 text-left">Year of Study</th>
                    <th className="p-2 text-left">Branch</th>
                    <th className="p-2 text-left">College Name</th>
                    <th className="p-2 text-left">Techk Shitiz Id</th>
                  </tr>
                </thead>
                <tbody>
                  {teamMemberDetails?.length > 0 ? (
                    teamMemberDetails?.map((item: any, index) => (
                      <tr key={index} className="border border-[#747a86]">
                        <th className="p-2" scope="row">
                          {index + 1}
                        </th>
                        <td className="p-2">{item.participant_Name}</td>
                        <td className="p-2">{item.participant_Email}</td>
                        <td className="p-2">{item.participant_Phone}</td>
                        <td className="p-2">
                          {item.participant_Registration_Number}
                        </td>
                        <td className="p-2">
                          {item.participant_Year_of_Study}
                        </td>
                        <td className="p-2">{item.participant_Branch}</td>
                        <td className="p-2">{item.participant_College_Name}</td>
                        <td className="p-2">{item.techk_Shitiz_Id}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="p-2" colSpan={20}>
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <div
        id="user_Id_admin_profile"
        className="w-full h-full rounded bg-[#150f15c4] text-white overflow-auto"
      >
        <div className="w-auto h-full p-5">
          <div className="overflow-x-auto">
            <table className="w-full border border-[#747a86] border-collapse">
              <thead>
                <tr className="border border-[#747a86]">
                  <th className="p-2 text-left">Sno</th>
                  <th className="p-2 text-left">Contest Name</th>
                  <th className="p-2 text-left">Contest Id</th>
                  <th className="p-2 text-left">Contest Start Date</th>
                  <th className="p-2 text-left">Contest End Date</th>
                  <th className="p-2 text-left">Contest Year</th>
                  <th className="p-2 text-left">Contest Club Name</th>
                  <th className="p-2 text-left">Contest Club Id</th>
                  <th className="p-2 text-left">Contest Url Id</th>
                  <th className="p-2 text-left">Contest End</th>
                  <th className="p-2 text-left">Contest Type</th>
                  <th className="p-2 text-left">Contest Mode Online</th>
                  <th className="p-2 text-left">Contest Event Name</th>
                  <th className="p-2 text-left">Contest Event Id</th>
                  <th className="p-2 text-left">Project Title</th>
                  <th className="p-2 text-left">Github Repo Link</th>
                  <th className="p-2 text-left">Hosted Project Link</th>
                  <th className="p-2 text-left">Project Documentation Pdf</th>
                  <th className="p-2 text-left">Leader Member Id</th>
                </tr>
              </thead>
              <tbody>
                {projectSubmittions?.length > 0 ? (
                  projectSubmittions?.map((item: any, index) => (
                    <tr key={index} className="border border-[#747a86]">
                      <th className="p-2" scope="row">
                        {index + 1}
                      </th>
                      <td className="p-2">{item.contest_Name}</td>
                      <td className="p-2">{item.contest_Id}</td>
                      <td className="p-2">{item.contest_Start_date}</td>
                      <td className="p-2">{item.contest_End_date}</td>
                      <td className="p-2">{item.contest_Year}</td>
                      <td className="p-2">{item.contest_Club_Name}</td>
                      <td className="p-2">{item.contest_Club_Id}</td>
                      <td className="p-2">{item.contestUrl_Id}</td>
                      <td className="p-2">{item.contest_End}</td>
                      <td className="p-2">{item.contest_Type}</td>
                      <td className="p-2">{item.ContestModeOnline}</td>
                      <td className="p-2">{item.contest_EventName}</td>
                      <td className="p-2">{item.contest_EventId}</td>
                      <td className="p-2">{item.project_title}</td>
                      <td className="p-2">
                        <a
                          href={item.github_repo_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Github Repo Link
                        </a>
                      </td>
                      <td className="p-2">
                        <a
                          href={item.hosted_project_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Hosted Project Link
                        </a>
                      </td>
                      <td className="p-2">
                        <a
                          href={item.project_documentation_Pdf}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Project Documentation Pdf
                        </a>
                      </td>
                      <td className="p-2">{item.leader_Member_Id}</td>
                      <td className="p-2">
                        <button
                          onClick={() => {
                            setIsTeamMemberDetailsPopupOpen(true);
                            setTeamMemberDetails(item.team_member_details);
                          }}
                          className="bg-blue-500 text-white p-1 rounded"
                        >
                          View Details
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="p-2" colSpan={20}>
                      No Data Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectSubmittions;
