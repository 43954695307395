import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HalfLoader from "../HalfLoader.tsx";
const baseUrl = process.env.REACT_APP_BACKEND_URL;
function VerifySiteIdrequest() {
  const [rejectionResionBoxOpen, SetrejectionResionBoxOpen] = useState(false);
  const [rejected_Resion, setrejected_Resion] = useState({
    rejected_Resion: "",
  });
  const [IsLoader, SetIsLoader] = useState(false);
  const UserData = useLocation().state;
  const [VerifyUserData, setVerifyUserData] = useState();
  const [IsloadingIdRequest, SetIsloadingIdRequest] = useState(true);
  const [IsLoaderVerify, setIsLoaderVerify] = useState(false);
  const navigate = useNavigate();
  const verifyUserDataFetch = async (id) => {
    try {
      SetIsloadingIdRequest(true);
      const res = await fetch(
        `${baseUrl}/api/v1/admin/auth/site-id/request/pending/filter/data/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (Object(data).data) {
          if (Object(data)?.data?.college_Id_Prof) {
            const uint8Array = new Uint8Array(
              Object(data).data.college_Id_Prof.data.data
            );
            const blob = new Blob([uint8Array], { type: "image/jpeg" });
            const dataUrl = URL.createObjectURL(blob);
            Object(data).data.college_Id_Prof = dataUrl;
          }
          // const uint8Array = new Uint8Array(Object(data).data.college_Id_Prof.data.data);
          // const blob = new Blob([uint8Array], { type: 'image/jpeg' });
          // const dataUrl = URL.createObjectURL(blob);
          // Object(data).data.college_Id_Prof = dataUrl
          if (Object(data).data.transaction_Screen_Short) {
            const uinttr8Array = new Uint8Array(
              Object(data).data.transaction_Screen_Short.data.data
            );
            const blobtr = new Blob([uinttr8Array], { type: "image/jpeg" });
            const trdataUrl = URL.createObjectURL(blobtr);
            Object(data).data.transaction_Screen_Short = trdataUrl;
          }
        }
        setVerifyUserData(data.data);
        SetIsloadingIdRequest(false);
      } else {
        SetIsloadingIdRequest(false);
      }
    } catch (error) {
      SetIsloadingIdRequest(false);
      toast.warning("Some technical issue");
    }
  };
  useEffect(() => {
    if (!UserData) {
      navigate("/api/user/site-id/request/verify");
    } else {
      console.log(UserData);

      verifyUserDataFetch(UserData._id);
    }
  }, []);
  const OnSubmitData = async (event) => {
    event.preventDefault();
    try {
      SetIsLoader(true);
      if (rejected_Resion.rejected_Resion && Object(VerifyUserData).email_Id) {
        if (
          Number(rejected_Resion.rejected_Resion.length) > 10 &&
          Number(rejected_Resion.rejected_Resion.length) < 200
        ) {
          const res = await fetch(
            `${baseUrl}/api/v1/admin/auth/student/request/reject/${
              Object(VerifyUserData)._id
            }`,
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                participant_Email: Object(VerifyUserData).email_Id,
                Rejection_Problem_Subject: rejected_Resion.rejected_Resion,
              }),
              credentials: "include",
            }
          );
          if (res.status === 200) {
            SetIsLoader(false);
            toast.success("Request Rejected sucessfully");
            navigate("/api/user/site-id/request/verify");
          } else if (res.status === 400) {
            SetIsLoader(false);
            toast.success("All field require");
          } else {
            SetIsLoader(false);
            toast.warning("Request Already Rejected");
            navigate("/api/user/site-id/request/verify");
          }
        } else {
          SetIsLoader(false);
          toast.warning("please enter the resion between 50 to 100 ");
        }
      } else {
        SetIsLoader(false);
        toast.error("All field require");
      }
    } catch (error) {
      SetIsLoader(false);
      toast.error("Some technical issue");
    }
  };
  const VerifySiteIdRequest = async () => {
    const verificitation = window.confirm(
      "Are you sure to Verify this user request"
    );
    if (verificitation) {
      try {
        setIsLoaderVerify(true);
        const {
          full_Name,
          email_Id,
          phone_Number,
          departement_Name,
          admission_Year,
          current_Year,
          Registration_Number,
          college_Name,
          Date_Of_Birth,
          college_Name_Id,
          branch_Id,
        } = Object(VerifyUserData);
        const res = await fetch(
          `${baseUrl}/api/v1/admin/auth/techkshitiz-student-id/genrated/${
            Object(VerifyUserData)._id
          }`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              full_Name,
              email_Id,
              phone_Number,
              departement_Name,
              admission_Year,
              current_Year,
              Registration_Number,
              college_Name,
              Date_Of_Birth,
              college_Name_Id,
              branch_Id,
            }),
            credentials: "include",
          }
        );
        const response = await res.json();
        if (res.status === 200) {
          toast.success("Veriification Done sucessfully");
          setIsLoaderVerify(false);
          navigate("/api/user/site-id/request/verify");
        } else if (res.status === 400) {
          setIsLoaderVerify(false);
          toast.error(response.message);
        } else if (res.status === 401) {
          setIsLoaderVerify(false);
          toast.error(response.message);
        } else if (res.status === 402) {
          setIsLoaderVerify(false);
          toast.error(response.message);
        } else if (res.status === 403) {
          setIsLoaderVerify(false);
          toast.error(response.message);
        } else {
          setIsLoaderVerify(false);
          toast.warning("Already verified");
          navigate("/api/user/site-id/request/verify");
        }
      } catch (error) {
        setIsLoaderVerify(false);
        toast.error("Some technical issue");
      }
    }
  };
  const RejectParticipantTechKshitizId = async () => {
    const verificitation = window.confirm(
      "Are you sure to reject the user request"
    );
    if (verificitation) {
      SetrejectionResionBoxOpen(true);
    } else {
      SetrejectionResionBoxOpen(false);
    }
  };
  if (IsloadingIdRequest) {
    return <HalfLoader message="Loading..." />;
  }
  return (
    <>
      <div className="w-[100%] h-[72vh] rounded-[4px] bg-[#150f15c4] overflow-y-auto overflow-x-hidden ">
        <form onSubmit={OnSubmitData} className="w-[100%] h-[100%]    ">
          <div className="w-[90%] h-[auto]   text-[#fff] rounded-[6px] p-[20px]">
            <div className="w-[100%] h-[28px] flex justify-start items-center gap-2  capitalize">
              <b className="text-[#3fff65]"> Name : </b>
              <p>
                {Object(VerifyUserData).full_Name === ""
                  ? "No participant"
                  : Object(VerifyUserData).full_Name}
              </p>
            </div>
            <div className="w-[100%] h-[28px] flex justify-start items-center gap-2  capitalize">
              <b className="text-[#3fff65]">Email Id : </b>
              <p>
                {Object(VerifyUserData).email_Id === ""
                  ? "No participant"
                  : Object(VerifyUserData).email_Id}
              </p>
            </div>
            <div className="w-[100%] h-[28px] flex justify-start items-center gap-2  capitalize">
              <b className="text-[#3fff65]">MobileNumber : </b>
              <p>{Object(VerifyUserData).phone_Number}</p>
            </div>
            <div className="w-[100%] h-[28px] flex justify-start items-center gap-2  capitalize">
              <b className="text-[#3fff65]">Registration Number : </b>
              <p>{Object(VerifyUserData).Registration_Number}</p>
            </div>
            <div className="w-[100%] h-[28px] flex justify-start items-center gap-2  capitalize">
              <b className="text-[#3fff65]">College Name : </b>
              <p>{Object(VerifyUserData).college_Name}</p>
            </div>
            <div className="w-[100%] h-[28px] flex justify-start items-center gap-2  capitalize">
              <b className="text-[#3fff65]">College Name Id : </b>
              <p>
                {Object(VerifyUserData).college_Name_Id
                  ? Object(VerifyUserData).college_Name_Id
                  : "Not Available"}
              </p>
            </div>
            <div className="w-[100%] h-[28px] flex justify-start items-center gap-2  capitalize">
              <b className="text-[#3fff65]">Date Of Birth : </b>
              <p>{Object(VerifyUserData).Date_Of_Birth}</p>
            </div>
            <div className="w-[100%] h-[28px] flex justify-start items-center gap-2  capitalize">
              <b className="text-[#3fff65]">Admission Year : </b>
              <p>{Object(VerifyUserData).admission_Year}</p>
            </div>
            <div className="w-[100%] h-[28px] flex justify-start items-center gap-2 capitalize">
              <b className="text-[#3fff65]">Department : </b>
              <p>{Object(VerifyUserData).departement_Name}</p>
            </div>
            <div className="w-[100%] h-[28px] flex justify-start items-center gap-2 capitalize">
              <b className="text-[#3fff65]">Department Id : </b>
              <p>
                {Object(VerifyUserData).branch_Id
                  ? Object(VerifyUserData).branch_Id
                  : "Not Available"}
              </p>
            </div>
            <div className="w-[100%] h-[28px] flex justify-start items-center gap-2  capitalize">
              <b className="text-[#3fff65]">Current Year : </b>
              <p>{Object(VerifyUserData).current_Year}</p>
            </div>
            <div className="w-[100%] h-[28px] flex justify-start items-center gap-2  capitalize">
              <b className="text-[#3fff65]">Name As Per Bank Account : </b>
              <p>
                {Object(VerifyUserData).name_as_per_bank_account
                  ? Object(VerifyUserData).name_as_per_bank_account
                  : "Not Available"}
              </p>
            </div>
            <div className="w-[100%] h-[28px] flex justify-start items-center gap-2  capitalize">
              <b className="text-[#3fff65]">Transaction Id: </b>
              <p>
                {Object(VerifyUserData).transaction_id
                  ? Object(VerifyUserData).transaction_id
                  : "Not Available"}
              </p>
            </div>
            <div className="w-[100%] h-[28px] flex justify-start items-center gap-2  capitalize">
              <b className="text-[#3fff65]">Bank Last 4-Digits/Upi Number: </b>
              <p>
                {Object(VerifyUserData).bank_last_four_digit_and_upi_Number
                  ? Object(VerifyUserData).bank_last_four_digit_and_upi_Number
                  : "Not Available"}
              </p>
            </div>
            <div className="w-[100%] h-[28px] flex justify-start items-center gap-2  capitalize">
              <b className="text-[#3fff65]">Payment Date: </b>
              <p>
                {Object(VerifyUserData).paymentDate
                  ? Object(VerifyUserData).paymentDate
                  : "Not Available"}
              </p>
            </div>
            <div className="w-[100%] h-[28px] flex justify-start items-center gap-2  capitalize">
              <b className="text-[#3fff65]">Pay Amount: </b>
              <p>
                {Object(VerifyUserData).paymentAmount
                  ? Object(VerifyUserData).paymentAmount
                  : "Not Available"}
              </p>
            </div>
            <div className="w-[100%] h-[auto] space-y-4 capitalize">
              <b className="text-[#3fff65]">College Id Prof : </b>
              <div className="w-[250px] h-[250px]">
                <img
                  className="w-[250px] h-[250px]"
                  src={Object(VerifyUserData).college_Id_Prof}
                  alt="college_Id_Prof"
                />
              </div>
            </div>
            <div className="w-[100%] h-[auto] space-y-4 mt-6 capitalize">
              <b className="text-[#3fff65]">Transaction Screen Short : </b>
              <div className="w-[250px] h-[250px]">
                <img
                  className="w-[250px] h-[250px]"
                  src={Object(VerifyUserData).transaction_Screen_Short}
                  alt="college_Id_Prof"
                />
              </div>
            </div>
          </div>

          <div className="w-[100%] p-[20px] flex justify-center items-center gap-8">
            <div
              className={`${
                rejectionResionBoxOpen ? "flex" : "hidden"
              } flex-col justify-center items-start`}
            >
              <label htmlFor="rejected_Resion" className="text-[#fff] mb-1">
                Rejection Resion:{" "}
              </label>
              <textarea
                name="rejected_Resion"
                required
                className=" outline-none   bg-[#ffffffb7] text-[#000]  p-2 pl-4 rounded-[5px]"
                placeholder="Enter the rejection resion"
                value={rejected_Resion?.rejected_Resion}
                onChange={(e) => {
                  setrejected_Resion({ rejected_Resion: e.target.value });
                }}
                id="rejected_Resion"
                cols={50}
                rows={5}
                style={{ resize: "none" }}
              ></textarea>
              <button
                type="submit"
                className={`${
                  IsLoader
                    ? "flex cursor-not-allowed bg-[#e7344cc7]"
                    : "cursor-pointer bg-[#e7344c]"
                } border-[1px] mt-4 border-[#30434e]  justify-center items-center gap-2  rounded-[10px]  w-[250px] p-[10px] text-[#fff]`}
              >
                Payment Rejected{" "}
                <div
                  className={`${
                    IsLoader ? "" : "hidden"
                  } w-[20px] h-[20px] animate-spin rounded-full border-[2px] border-[#fff] border-t-[#000]`}
                ></div>
              </button>
            </div>

            <button
              type="button"
              onClick={RejectParticipantTechKshitizId}
              className={`${
                rejectionResionBoxOpen ? "hidden" : ""
              } border-[1px] border-[#30434e] rounded-[10px] bg-[#e7344c] w-[250px] p-[10px] text-[#fff]`}
            >
              {" "}
              Participant Payment Rejected
            </button>
            <button
              type="button"
              onClick={VerifySiteIdRequest}
              className={`${
                IsLoaderVerify
                  ? "flex cursor-not-allowed bg-[#008000bd]"
                  : "cursor-pointer bg-[green]"
              } border-[1px] mt-4 border-[#30434e]  justify-center items-center gap-2  rounded-[10px]  w-[250px] p-[10px] text-[#fff]`}
            >
              Genrate User Id{" "}
              <div
                className={`${
                  IsLoaderVerify ? "" : "hidden"
                } w-[20px] h-[20px] animate-spin rounded-full border-[2px] border-[#fff] border-t-[#000]`}
              ></div>
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default VerifySiteIdrequest;
