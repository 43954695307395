import React from "react";
import "./components/Form.css";
import { Route, Routes } from "react-router-dom";
import AdminPanel from "./components/Admin/AdminPanel.tsx";
import Adimin_Panel_Box_Outline from "./components/Admin/Adimin_Panel_Box_Outline.tsx";
import Admin_Panel_question_Page from "./components/Admin/Admin_Panel_question_Page.tsx";
import Admin_Dash_Board_Numberical_Question from "./components/Admin/Coding/Admin_Dash_Board_Numberical_Question.tsx";
import Admin_Panel_All_Question_She from "./components/Admin/Admin_Panel_All_Question_She.tsx";
import Team_List_Total from "./components/Team_List_Total.tsx";
import Total_Participant from "./components/Total_Participant.tsx";
import AdminPanelClubAdd from "./components/Admin/AdminPanelClubAdd.tsx";
import AdminPanelContestAdd from "./components/Admin/contest/AdminPanelContestAdd.tsx";
import AdminPanelEventAdd from "./components/Admin/Event/AdminPanelEventAdd.tsx";
import Adimin_Panel_Team_Register from "./components/Admin/Adimin_Panel_Team_Register.tsx";
import Adimin_Panel_Hackathon_Team_Register from "./components/Admin/Adimin_Panel_Hackathon_Team_Register.tsx";
import Admin_Panel_CodingRegister_Data from "./components/Admin/Admin_Panel_CodingRegister_Data.tsx";
import Admin_Panel_QuizRegister_Data from "./components/Admin/Admin_Panel_QuizRegister_Data.tsx";
// import AdminPanelQuizParticipantUserIdCreate from './components/Admin/AdminPanelQuizParticipantUserIdCreate.tsx';
import McqAnswerSubmitShowAdminPanel from "./components/McqAnswerSubmitShowAdminPanel.tsx";
import NumericalAnswerSubmitShowAdminPanel from "./components/NumericalAnswerSubmitShowAdminPanel.tsx";
import PermissionEventandForm from "./components/Admin/permissions/PermissionEventandForm.tsx";
import GalleryImageAdded from "./components/Admin/GalleryImageAdded.tsx";
import AdminPanelSiteIdRequestVerify from "./components/Admin/AdminPanelSiteIdRequestVerify.tsx";
import VerifySiteIdrequest from "./components/Admin/VerifySiteIdrequest.tsx";
import AdminPanelWinnerParticipantAdd from "./components/Admin/AdminPanelWinnerParticipantAdd.tsx";
import ClubInchargeAdd from "./components/Admin/club/ClubInchargeAdd.tsx";
import EventParticipantPaymentHistory from "./components/Admin/Event/EventParticipantPaymentHistory.tsx";
import EventParticipantPaymentVerify from "./components/Admin/Event/EventParticipantPaymentVerify.tsx";
import EventRegisterDataShow from "./components/Admin/Event/EventRegisterDataShow.tsx";
import EventCoordinatorAdd from "./components/Admin/Event/EventCoordinatorAdd.tsx";
import BranchNameRegister from "./components/Admin/CollegeNameAndBranch/BranchNameRegister.tsx";
import CollegeNameregister from "./components/Admin/CollegeNameAndBranch/CollegeNameregister.tsx";
import CampusAmbassdorApplicationShow from "./components/Admin/CampusAmbassdor/CampusAmbassdorApplicationShow.tsx";
import LeaderBoard from "./components/Admin/Quiz/LeaderBoard.tsx";
import StudentAndProfessorPermissionChange from "./components/Admin/StudentAndProfessor/StudentAndProfessorPermissionChange.tsx";
import AdminPanelCodingParticipantUserIdCreate from "./components/Admin/AdminPanelCodingParticipantUserIdCreate.tsx";
import Data_Fetch_Api from "./components/contexts/Data_Fetch_Api.tsx";
import AdminLogin from "./components/Admin/AdminLogin.tsx";
import AdminLoginVerification from "./components/Admin/AdminLoginVerification.tsx";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CatrasoAnimationLoading from "./components/CatrasoAnimationLoading.tsx";
import Error from "./Error.tsx";
import AdminResetPassword from "./components/Admin/AdminResetPassword.tsx";
import AllPermissionGenrate from "./components/Admin/permissions/AllPermissionGenrate.tsx";
import ProjectSubmittions from "./components/Admin/projects/ProjectSubmittions.tsx";
function App() {
  const [IsEventListLoader, IsEventListError, EventDataList] = Data_Fetch_Api(
    "/api/v1/admin/auth/event/data/list/"
  );
  if (IsEventListLoader) {
    return <CatrasoAnimationLoading />;
  }
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/admin/dashboard/login" element={<AdminLogin />} />
        <Route
          path="/admin/dashboard/login/verification"
          element={<AdminLoginVerification />}
        />
        <Route
          path="/admin/dashboard/login/verification"
          element={<AdminLoginVerification />}
        />
        <Route
          path="/admin/password/forget/"
          element={<AdminResetPassword />}
        />
        {/*Admin Panel */}
        <Route path="/" element={<AdminPanel />}>
          <Route path="" element={<Adimin_Panel_Box_Outline />} />
          {Object(EventDataList)?.data?.map((EventData, index) => (
            <Route
              key={index}
              path={`api/event/data/list/${EventData._id}`}
              element={<EventRegisterDataShow />}
            />
          ))}
          <Route
            path="api/all-students/professor/permission"
            element={<StudentAndProfessorPermissionChange />}
          />
          <Route
            path="auth/permission/create"
            element={<AllPermissionGenrate />}
          />
          <Route path="auth/permission" element={<PermissionEventandForm />} />
          <Route
            path="api/team-register/contest/participant/list"
            element={<Adimin_Panel_Team_Register />}
          />
          <Route
            path="api/user/site-id/request/verify"
            element={<AdminPanelSiteIdRequestVerify />}
          />
          <Route
            path="api/site-id/request/verify"
            element={<VerifySiteIdrequest />}
          />
          <Route
            path="api/hackathon/team-register/contest/participant/list"
            element={<Adimin_Panel_Hackathon_Team_Register />}
          />
          <Route
            path="api/coding/contest/participant/list"
            element={<Admin_Panel_CodingRegister_Data />}
          />
          <Route
            path="api/quiz/contest/participant/list"
            element={<Admin_Panel_QuizRegister_Data />}
          />
          {/* <Route path='add/quiz/user-id/' element={<AdminPanelQuizParticipantUserIdCreate />} /> */}
          <Route
            path="add/coding/user-id/"
            element={<AdminPanelCodingParticipantUserIdCreate />}
          />
          <Route
            path="add/mcq-question"
            element={<Admin_Panel_question_Page />}
          />
          <Route
            path="api/winner-participant-add"
            element={<AdminPanelWinnerParticipantAdd />}
          />
          <Route
            path="api/total-member/contest/participant/list"
            element={<Total_Participant />}
          />
          <Route path="api/team-list/contest" element={<Team_List_Total />} />
          <Route
            path="add/numerical-question"
            element={<Admin_Dash_Board_Numberical_Question />}
          />
          <Route
            path="api/answer/mcq-question"
            element={<McqAnswerSubmitShowAdminPanel />}
          />
          <Route path="api/answer/leaderboard" element={<LeaderBoard />} />
          <Route
            path="api/answer/numerical-question"
            element={<NumericalAnswerSubmitShowAdminPanel />}
          />
          <Route
            path="api/team-list/contest"
            element={<Admin_Panel_question_Page />}
          />
          <Route
            path="api/all-student/contest/participant"
            element={<Admin_Panel_All_Question_She />}
          />
          <Route
            path="api/all-question-list/contest/mcq-question/numerical-question"
            element={<Admin_Panel_All_Question_She />}
          />
          <Route
            path="api/gallery/images/add/"
            element={<GalleryImageAdded />}
          />
          <Route path="api/club/add/" element={<AdminPanelClubAdd />} />
          <Route path="api/club/incharge/add/" element={<ClubInchargeAdd />} />
          <Route
            path="api/event/coordinator/add/"
            element={<EventCoordinatorAdd />}
          />
          <Route path="api/contest/add/" element={<AdminPanelContestAdd />} />
          <Route path="api/event/add" element={<AdminPanelEventAdd />} />
          <Route
            path="api/event/participant/payment/history"
            element={<EventParticipantPaymentHistory />}
          />
          <Route
            path="api/event/registration/payment/pending/data/verify"
            element={<EventParticipantPaymentVerify />}
          />
          <Route path="branch/name/register" element={<BranchNameRegister />} />
          <Route
            path="college/name/register"
            element={<CollegeNameregister />}
          />
          <Route
            path="campus/ambassdor/application/"
            element={<CampusAmbassdorApplicationShow />}
          />
          <Route
            path="online/contest/project/submition"
            element={<ProjectSubmittions />}
          />
        </Route>
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
}

export default App;
