import React, { useEffect, useState } from 'react'
import '../Form.css'
import { toast } from 'react-toastify';
import HalfLoader from '../HalfLoader.tsx';
const baseUrl = process.env.REACT_APP_BACKEND_URL;
function AdminPanelClubAdd() {
    const [IsLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
    const [Error, SetError] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<string>("");
    const [image, setImage] = useState(null);
    const [ClubName, setClubName] = useState<string>("");
    const [ClubNameAndClubId, SetClubNameAndClubId] = useState<string[]>([]);
    const [ClubId, setClubId] = useState<string>("");

    const handleFileChange = (event) => {
        if (event.target.value) {
            const file = event.target.files[0];
            if (file.type === "image/jpeg" || file.type === "image/png") {
                if (Number(file.size) >= 614400) {
                    toast.error('File size below the 600kb');
                }
                else {
                    setImage(file);
                    if (file) {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            setSelectedFile(String(reader.result));
                        };
                        reader.readAsDataURL(file);
                    } else {
                        setSelectedFile("");
                    }

                }
            } else {
                setSelectedFile("");
                toast.error('Only Support image/jpeg format');

            }
        }
    };
    const submidformdata = async (event) => {
        event.preventDefault();
        try {
            SetError(false)
            setIsLoadingRequest(true);
            if (ClubId && ClubName && ClubName) {

                const formData = new FormData();
                formData.append('club_Id', ClubId);
                formData.append('club_Name', ClubName);
                formData.append('contest_poster', Object(image));
                const res = await fetch(baseUrl + '/api/v1/admin/auth/gallery/images/add/data/', {
                    method: 'POST',
                    body: formData,
                    credentials: 'include'
                });
                if (res.status === 200) {
                    toast.success("Event Gallery Images  Added ");
                    setSelectedFile("");
                    setIsLoadingRequest(false);
                } else if (res.status === 500) {
                    toast.error("Some technical issue");
                    setIsLoadingRequest(false);
                } else if (res.status === 400) {
                    toast.error("All field require");
                    setIsLoadingRequest(false);
                } else {
                    setIsLoadingRequest(false);
                }
            } else {
                toast.error("All field require");
                setIsLoadingRequest(false);
            }
        } catch (error) {
            SetError(true);
            toast.error("some technical issue");
            setIsLoadingRequest(false);
        }

    }
    useEffect(() => {
        ClubNameClubIdFind();
    }, [])

    const ClubNameClubIdFind = async () => {
        try {
            setIsLoadingRequest(true)
            const res = await fetch(baseUrl + '/api/club/name/club-id/', {
                method: "GET",
                credentials: 'include'
            })
            const data = await res.json();
            SetClubNameAndClubId(data.data);
            setIsLoadingRequest(false)
        } catch (error) {
            console.log("Some technical issue");
            setIsLoadingRequest(false)

        }

    }
    const OnchangeClubNameClubIdData = (event) => {
        if (event.target.value) {
            let ClubData = JSON.parse(event.target.value);
            setClubId(ClubData.club_Id);
            setClubName(ClubData.clubname);
        }

    }

    if (IsLoadingRequest) {
        return <HalfLoader message="Processing.." />

    }
    if (Error) {
        return <h1>Something went wrong!!</h1>
    }
    return (
        <>
            <div className="container">
                <div className="title">Event Gallery Images Add</div>
                <hr />
                <div className="content">
                    <form onSubmit={submidformdata} encType='multipart/form-data'>
                        <div className="user-details">
                            <div className="input-box">
                                <span className="details">Gallery Image</span>
                                <input type="file" name='club_Poster' id='club_Poster' onChange={handleFileChange} required />
                            </div>
                            <div className="input-box">
                                <span className="details">Club Name</span>
                                <select name='event_Club_Name' id='event_Club_Name' onChange={OnchangeClubNameClubIdData} required  >
                                    <option value="">Select event club name</option>
                                    {
                                        Object(ClubNameAndClubId).map((data, index) => (
                                            <>
                                                <option key={index} value={JSON.stringify(data)} className=' capitalize'>{data.clubname}</option>
                                            </>
                                        )
                                        )
                                    }
                                </select>
                            </div>
                            <div className=' w-[300px] h-[250px] border-[2px] flex justify-center items-center border-[#000] rounded-[10px]'>
                                <img className='w-[100%] h-[100%] rounded-[10px]' src={selectedFile} alt='notSelected' />
                            </div>


                        </div>
                        <div className="button-container">
                            <div className="button">
                                <input type="submit" value="Add Club" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AdminPanelClubAdd
