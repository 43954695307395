import React from 'react'
import Data_Fetch_Api from '../contexts/Data_Fetch_Api.tsx'
import HalfLoader from '../HalfLoader.tsx';
import { useNavigate } from 'react-router-dom';

function AdminPanelSiteIdRequestVerify() {
    const navigate = useNavigate();
    const [IsloadingIdRequest, ErrorIdRequest, getdataIdRequest] = Data_Fetch_Api('/api/v1/admin/auth/site-id/request/pending/data');
    const [IsloadingIdRequestVerified, ErrorIdRequestVerified, getdataIdVerifiedRequest] = Data_Fetch_Api('/api/v1/admin/auth/site-id/request/verified/data');
    const VerifyUser = (index) => {
        navigate('/api/site-id/request/verify', { state: Object(getdataIdRequest).data[index] })
    }
    if (IsloadingIdRequest || IsloadingIdRequestVerified) {
        return <HalfLoader message="Loading.." />
    }
    if (ErrorIdRequest || ErrorIdRequestVerified) {
        return <h1>Some thing want wrong</h1>
    }
    return (
        <>

            <div className='w-[100%] h-[100%] rounded-[4px]  bg-[#150f15c4] text-[#fff] overflow-auto '>
                <div className='w-[auto] h-[100%]  p-[20px]'>
                    <table className=' w-[auto]   border-[1px]  border-[#747a86] border-collapse ' >
                        <tr className='border-[1px]   border-[#747a86] '>
                            <th className='p-[10px] text-left'>Full Name</th>
                            <th className='p-[10px] text-left'>Email Id</th>
                            <th className='p-[10px] text-left'>Phone Number</th>
                            <th className='p-[10px] text-left'>Departement Name</th>
                            <th className='p-[10px] text-left'>Current Year</th>
                            <th className='p-[10px] text-left'>Admission Year</th>
                            <th className='p-[10px] text-left'>Registration Number</th>
                            <th className='p-[10px] text-left'>College Name</th>
                            <th className='p-[10px] text-left'>Date Of Birth</th>
                            <th className='p-[10px] text-left'>Name As Per Bank Account</th>
                            <th className='p-[10px] text-left'>Transaction Id</th>
                            <th className='p-[10px] text-left'>Bank Last 4-Digits/Upi Number</th>
                            <th className='p-[10px] text-left'>Payment Date</th>
                            <th className='p-[10px] text-left'>Pay Amount</th>
                            <th className='p-[10px] text-left'>Verification Status</th>
                            <th className='p-[10px] text-left'>Verification Id</th>
                            <th className='p-[10px] text-center'>Verify</th>
                        </tr>
                        {
                            Object(getdataIdRequest).data ? (Object(getdataIdRequest).data[0] ? (
                                Object(getdataIdRequest).data.map((RequestData, index) => (
                                    <tr key={index} className=' border-[1px]     border-[#747a86]'>
                                        <td className='p-[10px] text-left  '>
                                            <div className='w-[200px] truncate'>{RequestData.full_Name}</div>
                                        </td>
                                        <td className='p-[10px] text-left  '>
                                            <div className='w-[300px] truncate'>{RequestData.email_Id}</div>
                                        </td>
                                        <td className='p-[10px]  text-left    '>
                                            <div className='w-[200px] truncate'>{RequestData.phone_Number}</div>
                                        </td>
                                        <td className='p-[10px] text-left  '>
                                            <div className='w-[400px] truncate'>{RequestData.departement_Name}</div>
                                        </td>
                                        <td className='p-[10px] text-left '><div className='w-[150px] truncate'>{RequestData.current_Year}</div></td>
                                        <td className='p-[10px] text-left '><div className='w-[200px] truncate'>{RequestData.admission_Year}</div></td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[200px] truncate'>{RequestData.Registration_Number}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[500px] truncate'>{RequestData.college_Name}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[200px] truncate'>{RequestData.Date_Of_Birth}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[350px] truncate'>{RequestData.name_as_per_bank_account ? RequestData.name_as_per_bank_account : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[350px] truncate'>{RequestData.transaction_id ? RequestData.transaction_id : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[350px] truncate'>{RequestData.bank_last_four_digit_and_upi_Number ? RequestData.bank_last_four_digit_and_upi_Number : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[250px] truncate'>{RequestData.paymentDate ? RequestData.paymentDate : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[200px] truncate'>{RequestData.paymentAmount ? RequestData.paymentAmount : "Not Available"}</div>
                                        </td>
                                        <td className={`p-[10px] text-left   ${RequestData.verify ? "text-[#39e639]" : "text-[red]"}`}>
                                            <div className='w-[200px]  font-[700] truncate '>{RequestData.verify ? "Verified" : "Pending"}</div>
                                        </td>
                                        <td className='p-[10px] text-left ' >
                                            <div className='w-[200px] truncate'>{RequestData.verify_Id ? RequestData.verify_Id : "Verification Pending"}</div>
                                        </td>
                                        <td className={`p-[20px] font-[700] ${RequestData.verify ? "text-[#39e639]" : "text-[#fff]"}`}>
                                            {RequestData.verify ? "Verified" : (

                                                <div onClick={() => VerifyUser(index)}>
                                                    <button type="button" className='border-[1px] border-[#30434e] rounded-[10px] bg-[green] w-[150px] p-[10px]'>Verify User</button>
                                                </div>
                                            )}
                                        </td>
                                    </tr>

                                ))
                            ) : (
                                <>
                                </>

                            )) : (
                                <></>
                            )
                        }
                        {
                            Object(getdataIdVerifiedRequest).data ? (Object(getdataIdVerifiedRequest).data[0] ? (
                                Object(getdataIdVerifiedRequest).data.map((RequestVerifiedData, index) => (
                                    <tr key={index} className=' border-[1px]     border-[#747a86]'>
                                        <td className='p-[10px] text-left  '>
                                            <div className='w-[200px] truncate'>{RequestVerifiedData.full_Name}</div>
                                        </td>
                                        <td className='p-[10px] text-left  '>
                                            <div className='w-[300px] truncate'>{RequestVerifiedData.email_Id}</div>
                                        </td>
                                        <td className='p-[10px]  text-left    '>
                                            <div className='w-[200px] truncate'>{RequestVerifiedData.phone_Number}</div>
                                        </td>
                                        <td className='p-[10px] text-left  '>
                                            <div className='w-[400px] truncate'>{RequestVerifiedData.departement_Name}</div>
                                        </td>
                                        <td className='p-[10px] text-left '><div className='w-[150px] truncate'>{RequestVerifiedData.current_Year}</div></td>
                                        <td className='p-[10px] text-left '><div className='w-[200px] truncate'>{RequestVerifiedData.admission_Year}</div></td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[200px] truncate'>{RequestVerifiedData.Registration_Number}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[500px] truncate'>{RequestVerifiedData.college_Name}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[200px] truncate'>{RequestVerifiedData.Date_Of_Birth}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[350px] truncate'>{RequestVerifiedData.name_as_per_bank_account ? RequestVerifiedData.name_as_per_bank_account : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[350px] truncate'>{RequestVerifiedData.transaction_id ? RequestVerifiedData.transaction_id : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[350px] truncate'>{RequestVerifiedData.bank_last_four_digit_and_upi_Number ? RequestVerifiedData.bank_last_four_digit_and_upi_Number : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[250px] truncate'>{RequestVerifiedData.paymentDate ? RequestVerifiedData.paymentDate : "Not Available"}</div>
                                        </td>
                                        <td className='p-[10px] text-left '>
                                            <div className='w-[200px] truncate'>{RequestVerifiedData.paymentAmount ? RequestVerifiedData.paymentAmount : "Not Available"}</div>
                                        </td>
                                        <td className={`p-[10px] text-left   ${RequestVerifiedData.verify ? "text-[#39e639]" : "text-[red]"}`}>
                                            <div className='w-[200px]  font-[700] truncate '>{RequestVerifiedData.verify ? "Verified" : "Pending"}</div>
                                        </td>
                                        <td className='p-[10px] text-left ' >
                                            <div className='w-[200px] truncate'>{RequestVerifiedData.verify_Id ? RequestVerifiedData.verify_Id : "Verification Pending"}</div>
                                        </td>
                                        <td className={`p-[20px] font-[700] ${RequestVerifiedData.verify ? "text-[#39e639]" : "text-[#fff]"}`}>
                                            {RequestVerifiedData.verify ? "Verified" : (

                                                <div onClick={() => VerifyUser(index)}>
                                                    <button type="button" className='border-[1px] border-[#30434e] rounded-[10px] bg-[green] w-[150px] p-[10px]'>Verify User</button>
                                                </div>
                                            )}
                                        </td>
                                    </tr>

                                ))
                            ) : (
                                <>
                                </>

                            )) : (
                                <></>
                            )
                        }
                    </table>
                </div>
            </div>
        </>
    )
}

export default AdminPanelSiteIdRequestVerify
