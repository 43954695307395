import React, { useState } from 'react';
import HalfLoader from '../../HalfLoader.tsx';
import { toast } from 'react-toastify';
const baseUrl = process.env.REACT_APP_BACKEND_URL
const CollegeNameregister = () => {
    const [collegeData, setCollegeData] = useState<string[]>({ college_Name: "", university_Name: "", college_Establish_Date: "", college_Establish_Year: "" });
    const [IsLoadingRequest, setIsLoadingRequest] = useState(false);
    const CollegeNameRegisterSave = async (e) => {
        setIsLoadingRequest(true);
        e.preventDefault();
        try {
            const { college_Name, university_Name, college_Establish_Date, college_Establish_Year } = Object(collegeData);
            if (college_Name && university_Name && college_Establish_Date && college_Establish_Year) {

                const res = await fetch(baseUrl + '/api/v1/admin/auth/college/name/register', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ college_Name, university_Name, college_Establish_Date, college_Establish_Year }),
                    credentials:'include'
                })
                if (res.status === 200) {
                    toast.success('College name register sucessfully');
                    setCollegeData({ college_Name: "", university_Name: "", college_Establish_Date: "", college_Establish_Year: "" });
                    setIsLoadingRequest(false);
                } else if (res.status === 402) {
                    toast.error('College name already exist');
                    setIsLoadingRequest(false);
                } else if (res.status === 400) {
                    setIsLoadingRequest(false)
                    toast.error('All field require');

                } else {
                    setIsLoadingRequest(false)
                    toast.error('Some technical issue');

                }
            } else {
                setIsLoadingRequest(false)
                toast.error('All field require');
            }
        } catch (error) {
            setIsLoadingRequest(false)
            toast.error('Some technical issue');

        }
    }
    if (IsLoadingRequest) {
        return <HalfLoader message="Processing..." />
    }
    const collegeDataChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setCollegeData({ ...collegeData, [name]: value });
    }
    return (
        <>
            <div className="container">
                <div className="title">College Name Add</div>
                <hr />
                <div className="content">
                    <form onSubmit={CollegeNameRegisterSave}>
                        <div className="user-details">
                            <div className="input-box">
                                <span className="details">College Name</span>
                                <input type="text" name='college_Name' value={Object(collegeData).college_Name} onChange={collegeDataChange} placeholder="Enter the college name" required />
                            </div>
                            <div className="input-box">
                                <span className="details">University Name</span>
                                <input type="text" name='university_Name' value={Object(collegeData).university_Name} onChange={collegeDataChange} placeholder="Enter the university name" required />
                            </div>
                            <div className="input-box">
                                <span className="details">College Establish Date</span>
                                <input type="date" name='college_Establish_Date' value={Object(collegeData).college_Establish_Date} onChange={collegeDataChange} placeholder="Enter the college establish date" required />
                            </div>
                            <div className="input-box">
                                <span className="details">College Establish Year</span>
                                <input type="number" name='college_Establish_Year' value={Object(collegeData).college_Establish_Year} onChange={collegeDataChange} placeholder="Enter the college establish year" required />
                            </div>
                        </div>
                        <div className="button-container">
                            <div className="button">
                                <input type="submit" value="Add Name" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </>
    );
}

export default CollegeNameregister;
