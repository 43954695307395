import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.tsx';
import { BrowserRouter } from "react-router-dom";
const rootTag = document.getElementById('root');
if (rootTag) {
  const root = ReactDOM.createRoot(rootTag);
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}