import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import HalfLoader from '../HalfLoader.tsx';
const baseUrl = process.env.REACT_APP_BACKEND_URL;
function Admin_Panel_All_Question_She() {

    const [getdata, setdata] = useState<string[]>([]);
    const [getdatamcq, setdatamcq] = useState<string[]>([]);
    const [IsLoader, SetLoader] = useState<boolean>(false);
    const Answersheetdata = async () => {
        try {
            SetLoader(true);
            const res = await fetch(baseUrl + "/api/v1/admin/auth/numerical/all/question/list", {
                method: 'GET',
                credentials: 'include'
            })
            const res2 = await fetch(baseUrl + '/api/v1/admin/auth/mcq/all/question/list', {
                method: 'GET',
                credentials: 'include'
            })
            const data = await res.json();
            const data2 = await res2.json();
            setdatamcq(data2.data ? data2.data : []);
            setdata(data.data ? data.data : []);
            SetLoader(false);

        } catch (error) {
            toast.error("Some technical issue please Reload the page!!");
            SetLoader(false);
        }
    }
    const datadelete = async (id) => {
        try {

            const verify = window.confirm("Are you sure to delete data?");
            if (verify) {
                const res = await fetch(`${baseUrl}/api/question/type/numerical/delete/${id}`, { method: 'DELETE', credentials: 'include' });
                if (res.status === 200) {
                    toast.success('Data Deleted sucessfully');
                    Answersheetdata();

                } else if (res.status === 403) {
                    toast.error('Some technical issue please reload the page')

                }
            }
        } catch (error) {
            toast.error('Some technical issue' + error);
        }
    }
    const datadeletemcq = async (id) => {
        try {

            const verify = window.confirm("Are you sure to delete data?");
            if (verify) {
                const res = await fetch(`${baseUrl}/api/question/type/mcq/delete/id/${id}`, { method: 'DELETE', credentials: 'include' });
                if (res.status === 200) {
                    toast.success('Data Deleted sucessfully');
                    Answersheetdata();

                } else if (res.status === 403) {
                    toast.error('Some technical issue please reload the page')

                }
            }
        } catch (error) {
            toast.error('Some technical issue' + error);
        }
    }

    useEffect(() => {
        Answersheetdata();
    }, [])

    if (IsLoader) {
        return <HalfLoader message="Loading.." />
    }

    return (
        <>
            <div className='w-[100%] h-[80vh] rounded-[4px]  overflow-auto overflow-x-hidden space-y-6'>
                <div className=' p-[30px] flex justify-center items-center'>
                    <h1 className=' text-[40px] font-[700]'>Mcq Question List : </h1>
                </div>
                {getdatamcq.map((element, index) => (
                    <div className='w-[100%] h-[auto]  rounded-[4px]  flex justify-center items-center '>
                        <div className='w-[90%] h-[auto]  bg-[#150f15c4] text-[#fff] rounded-[6px] p-[20px]'>

                            <div className='w-[100%] h-[auto] flex justify-end items-center gap-4'>
                                <span className="material-symbols-outlined cursor-pointer text-[#ff5165] font-[500]" onClick={() => { datadelete(Object(element)._id) }}>
                                    delete
                                </span>
                            </div>
                            <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                <b className='text-[#3fff65]' >Event Name : </b><p>{Object(element)?.event_Name}</p>
                            </div>
                            <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                <b className='text-[#3fff65]' >Event Id : </b><p>{Object(element)?.event_Id}</p>
                            </div>
                            <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                <b className='text-[#3fff65]' >Event Year : </b><p>{Object(element)?.event_Year}</p>
                            </div>
                            <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                <b className='text-[#3fff65]' >Event Club Name : </b><p>{Object(element)?.event_Club_Name}</p>
                            </div>
                            <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                <b className='text-[#3fff65]' >Event Club Id : </b><p>{Object(element)?.event_Club_Id}</p>
                            </div>
                            <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                <b className='text-[#3fff65]' >Contest Name : </b><p>{Object(element)?.contest_Name}</p>
                            </div>
                            <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                <b className='text-[#3fff65]' >Contest Id : </b><p>{Object(element)?.contest_Id}</p>
                            </div>
                            <div key={index} className='w-[100%] h-[auto] flex justify-start items-start gap-2  '>
                                <div className=' w-[120px]'><b className='text-[#3fff65]  uppercase'>Question  : </b></div><div className='w-[700px] flex flex-col gap-4 h-[auto] bg-[#07101e] text-[#efefef] p-[30px]  pt-2 rounded-[5px]'>{Object(element).questionData?.map((answerSubmitData) => (
                                    <div>

                                        <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                            <b className='text-[#3fff65]' >Question Name : </b><p>{Object(answerSubmitData).questionname}</p>
                                        </div>
                                        <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                            <b className='text-[#3fff65]' >Question Number : </b><p>{Object(answerSubmitData).questionnumber}</p>
                                        </div>
                                        <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                            <b className='text-[#3fff65]' >Question Point : </b><p>{Object(answerSubmitData).questionPoint}</p>
                                        </div>
                                        <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                            <b className='text-[#3fff65]' >Correct Answer : </b><p>{Object(answerSubmitData).mcqanswer}</p>
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>


                        </div>
                    </div>
                ))}
                <div className=' p-[30px] flex justify-center items-center'>
                    <h1 className=' text-[40px] font-[700]'>Numerical Question List : </h1>
                </div>
                {getdata.map((element, index) => (
                    <>
                        <div className='w-[100%] h-[auto] mb-10  rounded-[4px]  flex justify-center items-center '>
                            <div className='w-[90%] h-[auto]  bg-[#150f15c4] text-[#fff] rounded-[6px] p-[20px]'>

                                <div className='w-[100%] h-[auto] flex justify-end items-center gap-4'>

                                    <span className="material-symbols-outlined cursor-pointer text-[#ff5165] font-[500]" onClick={() => { datadelete(Object(element)._id) }}>
                                        delete
                                    </span>
                                </div>
                                <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                    <b className='text-[#3fff65]' >Event Name : </b><p>{Object(element)?.event_Name}</p>
                                </div>
                                <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                    <b className='text-[#3fff65]' >Event Id : </b><p>{Object(element)?.event_Id}</p>
                                </div>
                                <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                    <b className='text-[#3fff65]' >Event Year : </b><p>{Object(element)?.event_Year}</p>
                                </div>
                                <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                    <b className='text-[#3fff65]' >Event Club Name : </b><p>{Object(element)?.event_Club_Name}</p>
                                </div>
                                <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                    <b className='text-[#3fff65]' >Event Club Id : </b><p>{Object(element)?.event_Club_Id}</p>
                                </div>
                                <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                    <b className='text-[#3fff65]' >Contest Name : </b><p>{Object(element)?.contest_Name}</p>
                                </div>
                                <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                    <b className='text-[#3fff65]' >Contest Id : </b><p>{Object(element)?.contest_Id}</p>
                                </div>
                                <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                    <b className='text-[#3fff65]' >Contest Time : </b><p>{Number(Object(element)?.contest_time)}</p>
                                </div>
                                <div key={index} className='w-[100%] h-[auto] flex justify-start items-start gap-2  '>
                                    <div className=' w-[120px]'><b className='text-[#3fff65]  uppercase'>Question : </b></div>
                                    <div className='w-[700px] flex flex-col gap-4 h-[auto] bg-[#07101e] text-[#efefef] p-[30px]  pt-2 rounded-[5px]'>{Object(element).questionData?.map((QuestionData, index) => (
                                        <div key={index} className=' w-full'>

                                            <div className='w-[100%] h-[28px]'>
                                                <b className='text-[#3fff65]' >Question Number : </b><span >{Object(QuestionData).questionnumber}</span>
                                            </div>
                                            <div className='w-[100%] h-[28px] '>
                                                <b className='text-[#3fff65]' >Question Title : </b><span className=' ml-2'>{Object(QuestionData).question_Title}</span>
                                            </div>
                                            <div className='w-[100%] '>
                                                <b className='text-[#3fff65]' >Question Name : </b><span className=' ml-2'>{Object(QuestionData).questionname}</span>
                                            </div>
                                            <div className='w-[100%]'>
                                                <b className='text-[#3fff65]' >Question User Find : </b><span className=' ml-2'>{Object(QuestionData).answer_Find}</span>
                                            </div>
                                            <div className='w-[100%]'>
                                                <b className='text-[#3fff65]' >Question Example1 : </b><span className=' ml-2'>{Object(QuestionData).example_One}</span>
                                            </div>
                                            <div className='w-[100%]'>
                                                <b className='text-[#3fff65]' >Question Example2 : </b><span className=' ml-2'>{Object(QuestionData).example_Two}</span>
                                            </div>
                                            <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                                <b className='text-[#3fff65]' >Question Point : </b><p>{Object(QuestionData).question_Point}</p>
                                            </div>
                                            <div className='w-[100%] h-[28px] flex justify-start items-center gap-2  uppercase'>
                                                <b className='text-[#3fff65]' >Question Label : </b><p>{Object(QuestionData).question_label}</p>
                                            </div>
                                            <div className='w-[100%] h-[28px] '>
                                                <b className='text-[#3fff65]' >Topic Tag : </b><span className=' ml-2'>{Object(QuestionData).topic_Tag ? Object(QuestionData).topic_Tag : "Not Available"}</span>
                                            </div>
                                            <div className='w-[100%] h-[28px]'>
                                                <b className='text-[#3fff65]' >Test Cases : </b><span className=' ml-2'>{Object(QuestionData).testCases}</span>
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </>
                ))}



            </div>


        </>
    )
}

export default Admin_Panel_All_Question_She
