import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import HalfLoader from '../../HalfLoader.tsx';
const baseUrl = process.env.REACT_APP_BACKEND_URL;

function Admin_Dash_Board_Numberical_Question() {
    const [questionnumbers, setquestionnumber] = useState<number>(0);
    const [getdata, setdata] = useState({ questionname: '', question_Title: "", answer_Find: "", example_One: "", example_Two: "", question_label: "", question_Point: "", topic_Tag: "", EventId: "", testCases: "" });
    const [IsLoading, SetIsLoader] = useState<boolean>(true);
    const [Error, SetError] = useState<boolean>(false);
    const [IsLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
    const [contestClubData, SetContestClubData] = useState([]);
    const [ContestDataFilter, SetContestDataFilter] = useState({ ContestId: "", ContestName: "" });
    const Contestdatafetch = async () => {
        try {
            SetIsLoader(true)
            SetError(false)
            const datares = await fetch(baseUrl + '/api/v1/admin/auth/coding/contest/data/show', { method: 'GET', credentials: 'include' })
            const data = await datares.json();
            SetContestClubData(data.data);
            SetIsLoader(false)

        } catch (error) {
            SetIsLoader(false);
            SetError(true)

        }
    }
    useEffect(() => {
        Contestdatafetch();
    }, [])

    const datafetch = async (contest_Id) => {
        try {
            SetError(false)
            const datares = await fetch(`${baseUrl}/api/v1/admin/auth/coding/numerical/question/show/${contest_Id}`, { method: 'GET', credentials: 'include' })
            const data = await datares.json();
            if (data?.data?.questionData) {
                setquestionnumber(data.data.questionData.length + 1);
            } else {
                setquestionnumber(1);
            }
        } catch (error) {
            SetError(true)
        }
    }

    if (Error) {
        return <h1>Something went wrong</h1>
    }
    if (IsLoading) {
        return <HalfLoader message="Loading.." />
    }
    if (IsLoadingRequest) {
        return <HalfLoader message="Processing.." />
    }
    const changedata = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setdata({ ...getdata, [name]: value });
    }
    const submitformdata = async (event) => {
        event.preventDefault();
        try {
            setIsLoadingRequest(true)

            const { questionname, question_Title, answer_Find, example_One, example_Two, question_label, question_Point, topic_Tag, EventId, testCases } = getdata;
            const { ContestId, ContestName } = ContestDataFilter;
            if (questionname && question_Title && answer_Find && example_One && example_Two && question_label && question_Point && EventId && testCases) {
                const res = await fetch(baseUrl + '/api/v1/admin/auth/coding/numerical/question/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ questionname, questionnumber: questionnumbers, question_Title, answer_Find, example_One, example_Two, question_label, question_Point, topic_Tag, ContestName, ContestId, testCases, EventId }),
                    credentials: 'include'
                })
                if (res.status === 200) {
                    toast.success('Done Question Added');
                    datafetch(ContestId);
                    setdata({ questionname: '', question_Title: "", answer_Find: "", example_One: "", example_Two: "", question_label: "", question_Point: "", topic_Tag: "", EventId: "", testCases: "" });
                    setIsLoadingRequest(false)

                } else if (res.status === 401) {
                    toast.error("Invalid event id");
                    setIsLoadingRequest(false)


                } else if (res.status === 402) {
                    toast.error("Question number already exist");
                    setIsLoadingRequest(false)


                } else if (res.status === 400) {
                    toast.error("All field require");
                    setIsLoadingRequest(false)


                } else {
                    toast.error("Some technical issue");
                    setIsLoadingRequest(false)

                }

            } else {

                toast.error("All field require");
                setIsLoadingRequest(false)
            }
        } catch (error) {
            setIsLoadingRequest(false)
        }
    }
    const clubDataChange = (e) => {
        const value = e.target.value;
        if (value) {
            const contestDataGet = JSON.parse(value);
            datafetch(String(contestDataGet.contest_Id))
            SetContestDataFilter({ "ContestId": String(contestDataGet.contest_Id), "ContestName": String(contestDataGet.contest_Name) })
            setdata({ ...getdata, "EventId": String(contestDataGet.contest_EventId) });
        }
    }


    return (
        <>
            <div className="container ">
                <div className="title">Add Questions</div>
                <hr />
                <div className="content">
                    <form onSubmit={submitformdata} >
                        <div className="user-details flex flex-col w-full h-auto">

                            <div className="input-box w-full" >
                                <span className="details  font-[700] ">Question Title<span className='text-[#ff3434] text-[18px]'>*</span> : </span>
                                <textarea name='question_Title' value={getdata.question_Title} onChange={changedata} typeof='text' className=' mt-2 text-[#fff] outline-none rounded-[2px] resize-none bg-[#2b2525] p-[20px]' placeholder="Enter your Question Title" rows={2} cols={72} required />
                            </div>
                            <div className="input-box">
                                <span className="details  font-[700] ">Question<span className='text-[#ff3434] text-[18px]'>*</span>: </span>
                                <textarea name='questionname' value={getdata.questionname} onChange={changedata} typeof='text' className=' mt-2 text-[#fff] outline-none rounded-[2px] resize-none bg-[#2b2525] p-[20px]' placeholder="Enter your Question" rows={10} cols={72} required />
                            </div>
                            <div className="input-box">
                                <span className="details  font-[700] w-full ">What would be find user question<span className='text-[#ff3434] text-[18px]'>*</span> : </span>
                                <textarea name='answer_Find' value={getdata.answer_Find} onChange={changedata} typeof='text' className=' mt-2 text-[#fff] outline-none rounded-[2px] resize-none bg-[#2b2525] p-[20px]' placeholder="What would be find user given in this question" rows={10} cols={72} required />
                            </div>
                            <div className="input-box">
                                <span className="details  font-[700] w-full ">Example One<span className='text-[#ff3434] text-[18px]'>*</span> : </span>
                                <textarea name='example_One' value={getdata.example_One} onChange={changedata} typeof='text' className=' mt-2 text-[#fff] outline-none rounded-[2px] resize-none bg-[#2b2525] p-[20px]' placeholder="Enter your question first example" rows={10} cols={72} required />
                            </div>
                            <div className="input-box">
                                <span className="details  font-[700] w-full ">Example Two<span className='text-[#ff3434] text-[18px]'>*</span> : </span>
                                <textarea name='example_Two' value={getdata.example_Two} onChange={changedata} typeof='text' className=' mt-2 text-[#fff] outline-none rounded-[2px] resize-none bg-[#2b2525] p-[20px]' placeholder="Enter your question second example" rows={10} cols={72} required />
                            </div>
                            <div className="input-box">
                                <span className="details  font-[700] w-full ">Question Topic Tag <span className='text-[#0d0c0c] text-[18px]'> (Optional)</span> : </span>
                                <textarea name='topic_Tag' value={getdata.topic_Tag} onChange={changedata} typeof='text' className=' mt-2 text-[#fff] outline-none rounded-[2px] resize-none bg-[#2b2525] p-[20px]' placeholder="Enter your question topic" rows={4} cols={72} />
                            </div>

                            <div className="flex gap-4 w-full">
                                <div>
                                    <span className="details ">Test Cases<span className='text-[#ff3434] text-[18px]'>*</span> : </span>
                                    <textarea name='testCases' value={getdata.testCases} onChange={changedata} typeof='text' className=' mt-2 text-[#fff] outline-none rounded-[2px] resize-none bg-[#2b2525] p-[20px]' placeholder="Add test cases" rows={2} cols={32} required />

                                </div>
                                <div className=' mt-4'>
                                    <span className="details">Contest Name <span className='text-[#ff3434] text-[18px]'>*</span> </span>
                                    <select name="contest_Name_And_Id" onChange={clubDataChange} id="" required>
                                        <option value="">Select a contest name</option>
                                        {
                                            contestClubData?.length > 0 ? (
                                                Object(contestClubData).map((data, index) => (
                                                    <option key={index} value={JSON.stringify(data)}>{data?.contest_Name}</option>
                                                ))
                                            ) : ("")
                                        }
                                    </select>

                                </div>
                            </div>
                            <div className='flex w-full h-auto gap-2'>
                                <div className="input-box">
                                    <span className="details  font-[700] w-full ">Question Points<span className='text-[#ff3434] text-[18px]'>*</span> : </span>
                                    <input type="number" name="question_Point" id="question_Point" onChange={changedata} value={getdata?.question_Point} placeholder='Enter your question point' />
                                </div>
                                <div className="input-box">
                                    <span className="details  font-[700] w-full ">Question Label<span className='text-[#ff3434] text-[18px]'>*</span> : </span>
                                    <select name="question_label" onChange={changedata} value={getdata.question_label} id="question_label">
                                        <option value="">Select Question Label</option>
                                        <option value="School">School</option>
                                        <option value="Basic">Basic</option>
                                        <option value="Easy">Easy</option>
                                        <option value="Medium">Medium</option>
                                        <option value="Hard">Hard</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div className="button-container">
                            <div className="button">
                                <input type="submit" value="Add Question" />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Admin_Dash_Board_Numberical_Question
