import React, { useEffect, useState } from 'react';
import CatrasoAnimationLoading from '../../CatrasoAnimationLoading.tsx';
const baseUrl = process.env.REACT_APP_BACKEND_URL
const CampusAmbassdorApplicationShow = () => {
    const [IsloadingCampusAmbassdorDataLoading, SetIsloadingCampusAmbassdorDataLoading] = useState(false);
    const [CampusAmbassdorData, SetCampusAmbassdorData] = useState([]);
    const CampusAmbassdorDataFetch = async () => {
        SetIsloadingCampusAmbassdorDataLoading(true);
        try {
            const res = await fetch(baseUrl + '/api/v1/admin/auth/campus/ambassdor/application/request/', {
                method: 'GET',
                credentials:'include'
            })
            if (res.status === 200) {
                const data = await res.json();
                SetCampusAmbassdorData(data);
                SetIsloadingCampusAmbassdorDataLoading(false);
            } else {

                SetIsloadingCampusAmbassdorDataLoading(false);
            }

        } catch (error) {
            SetIsloadingCampusAmbassdorDataLoading(false);
        }

    }
    useEffect(() => {
        CampusAmbassdorDataFetch();
    }, [])
    if (IsloadingCampusAmbassdorDataLoading) {
        return <CatrasoAnimationLoading />
    }
    return (
        <>
            <div id='user_Id_admin_profile' className='w-[100%] h-[100%] rounded-[4px]  bg-[#150f15c4] text-[#fff] overflow-auto '>
                <div className='w-[auto] h-[100%]  p-[20px]'>
                    {
                        Object(CampusAmbassdorData)?.data?.length > 0 ? (
                            <table className=' w-[auto]   border-[1px]  border-[#747a86] border-collapse ' >
                                <tr className='border-[1px]   border-[#747a86] '>
                                    <th className='p-[10px] text-left'>Sno</th>
                                    <th className='p-[10px] text-left'>Full Name</th>
                                    <th className='p-[10px] text-left'>TechKshitiz Id</th>
                                    <th className='p-[10px] text-left'>Email Id</th>
                                    <th className='p-[10px] text-left'>Phone Number</th>
                                    <th className='p-[10px] text-left'>Departement Name</th>
                                    <th className='p-[10px] text-left'>Current Year</th>
                                    <th className='p-[10px] text-left'>Admission Year</th>
                                    <th className='p-[10px] text-left'>Registration Number</th>
                                    <th className='p-[10px] text-left'>College Name</th>
                                    <th className='p-[10px] text-left'>Date Of Birth</th>
                                    <th className='p-[10px] text-left'>Dietary Restrictions</th>
                                    <th className='p-[10px] text-left'>Emergency Contact Name</th>
                                    <th className='p-[10px] text-left'>Emergency Contact Phone Number</th>
                                    <th className='p-[10px] text-left'>Emergency Contact Relationship</th>
                                    <th className='p-[10px] text-left'>Event Fees</th>
                                    <th className='p-[10px] text-left'>Event Participation History</th>
                                    <th className='p-[10px] text-left'>Gender</th>
                                    <th className='p-[10px] text-left'>Housing Accommodations</th>
                                    <th className='p-[10px] text-left'>Previous Event Participation History</th>
                                    <th className='p-[10px] text-left'>Residential Address</th>
                                    <th className='p-[10px] text-left'>Special Accommodations</th>
                                    <th className='p-[10px] text-left'>T-Shirt Size</th>
                                    <th className='p-[10px] text-left'>Transportation Arrangements</th>
                                    <th className='p-[10px] text-left'>Volunteering Interest</th>
                                    <th className='p-[10px] text-left'>Role</th>
                                    <th className='p-[10px] text-left'>Campus Ambassdor Status</th>

                                </tr>
                                {
                                    Object(CampusAmbassdorData).data ? (Object(CampusAmbassdorData).data[0] ? (
                                        Object(CampusAmbassdorData).data.map((RequestData, index) => (
                                            <tr key={index} className=' border-[1px]     border-[#747a86]'>
                                                <td className='p-[10px] text-left  '>
                                                    <div className='w-[50px]  truncate capitalize flex justify-center items-center'>{index + 1}</div>
                                                </td>
                                                <td className='p-[10px] text-left  '>
                                                    <div className='w-[200px]  truncate capitalize'>{RequestData.participant_Name}</div>
                                                </td>
                                                <td className='p-[10px] text-left  '>
                                                    <div className='w-[300px]  truncate capitalize'>{RequestData.techk_Shitiz_Id}</div>
                                                </td>
                                                <td className='p-[10px] text-left  '>
                                                    <div className='w-[300px]  truncate capitalize'>{RequestData.participant_Email}</div>
                                                </td>
                                                <td className='p-[10px]  text-left    '>
                                                    <div className='w-[200px]  truncate capitalize'>{RequestData.participant_Phone}</div>
                                                </td>
                                                <td className='p-[10px] text-left  '>
                                                    <div className='w-[400px]  truncate capitalize'>{RequestData.participant_Branch}</div>
                                                </td>
                                                <td className='p-[10px] text-left '><div className='w-[150px]  truncate capitalize'>{RequestData.participant_Year_of_Study}</div></td>
                                                <td className='p-[10px] text-left '><div className='w-[200px]  truncate capitalize'>{RequestData.participant_Admission_Year}</div></td>
                                                <td className='p-[10px] text-left '>
                                                    <div className='w-[200px]  truncate capitalize'>{RequestData.participant_Registration_Number}</div>
                                                </td>
                                                <td className='p-[10px] text-left '>
                                                    <div className='w-[400px]  truncate capitalize'>{RequestData.participant_College_Name}</div>
                                                </td>
                                                <td className='p-[10px] text-left '>
                                                    <div className='w-[100px]  truncate capitalize'>{RequestData.participant_Date_of_Birth}</div>
                                                </td>
                                                <td className='p-[10px] text-left '>
                                                    <div className='w-[300px]  truncate capitalize'>{RequestData.participant_Dietary_Restrictions ? RequestData.participant_Dietary_Restrictions : "Not Available"}</div>
                                                </td>
                                                <td className='p-[10px] text-left '>
                                                    <div className='w-[300px]  truncate capitalize'>{RequestData.participant_Emergency_Contact_Name ? RequestData.participant_Emergency_Contact_Name : "Not Available"}</div>
                                                </td>
                                                <td className='p-[10px] text-left '>
                                                    <div className='w-[300px]  truncate capitalize'>{RequestData.participant_Emergency_Contact_Phone_Number ? RequestData.participant_Emergency_Contact_Phone_Number : "Not Available"}</div>
                                                </td>
                                                <td className='p-[10px] text-left '>
                                                    <div className='w-[300px]  truncate capitalize'>{RequestData.participant_Emergency_Contact_Relationship ? RequestData.participant_Emergency_Contact_Relationship : "Not Available"}</div>
                                                </td>
                                                <td className='p-[10px] text-left '>
                                                    <div className='w-[300px]  truncate capitalize'>{RequestData.participant_Event_Fees ? RequestData.participant_Event_Fees : "Not Available"}</div>
                                                </td>
                                                <td className='p-[10px] text-left '>
                                                    <div className='w-[300px]  truncate capitalize'>{RequestData.participant_Event_Participation_History ? RequestData.participant_Event_Participation_History : "Not Available"}</div>
                                                </td>
                                                <td className='p-[10px] text-left '>
                                                    <div className='w-[150px]  truncate capitalize'>{RequestData.participant_Gender ? RequestData.participant_Gender : "Not Available"}</div>
                                                </td>
                                                <td className='p-[10px] text-left '>
                                                    <div className='w-[250px]  truncate capitalize'>{RequestData.participant_Housing_Accommodations ? RequestData.participant_Housing_Accommodations : "Not Available"}</div>
                                                </td>
                                                <td className='p-[10px] text-left '>
                                                    <div className='w-[300px]  truncate capitalize'>{RequestData.participant_Previous_Event_Participation_History ? RequestData.participant_Previous_Event_Participation_History : "Not Available"}</div>
                                                </td>
                                                <td className='p-[10px] text-left '>
                                                    <div className='w-[200px]  truncate capitalize'>{RequestData.participant_Residential_Address ? RequestData.participant_Residential_Address : "Not Available"}</div>
                                                </td>
                                                <td className='p-[10px] text-left '>
                                                    <div className='w-[200px]  truncate capitalize'>{RequestData.participant_Special_Accommodations ? RequestData.participant_Special_Accommodations : "Not Available"}</div>
                                                </td>
                                                <td className='p-[10px] text-left '>
                                                    <div className='w-[200px]  truncate capitalize'>{RequestData.participant_T_Shirt_Size ? RequestData.participant_T_Shirt_Size : "Not Available"}</div>
                                                </td>
                                                <td className='p-[10px] text-left '>
                                                    <div className='w-[250px]  truncate capitalize'>{RequestData.participant_Transportation_Arrangements ? RequestData.participant_Transportation_Arrangements : "Not Available"}</div>
                                                </td>
                                                <td className='p-[10px] text-left '>
                                                    <div className='w-[200px]  truncate capitalize'>{RequestData.participant_Volunteering_Interest ? RequestData.participant_Volunteering_Interest : "Not Available"}</div>
                                                </td>
                                                <td className='p-[10px] text-left'>
                                                    <div className='w-[200px]  truncate capitalize'>{Number(RequestData.participant_Roll) === 0 ? "Intra College Student" : RequestData.participant_Roll === 1 ? "Student" : Number(RequestData.participant_Roll) === 2 ? "Professor" : Number(RequestData.participant_Roll) === 3 ? "Club Manager" : Number(RequestData.participant_Roll) === 4 ? "Campus Ambassador" : "Not Available"}
                                                        <span className=' ml-2'>{RequestData?.participant_Club_Roll_id}</span></div>
                                                </td>
                                                <td className='p-[10px] text-left '>
                                                    <div className={`w-[300px]  truncate capitalize ${RequestData?.participant_Campus_Ambassdor_Application_Verified ? "text-[#3ae23d]" : "text-[#ff3737]"} font-[700]`}>{RequestData.participant_Campus_Ambassdor_Status ? RequestData.participant_Campus_Ambassdor_Status : "Not Available"}</div>
                                                </td>
                                            </tr>

                                        ))
                                    ) : (
                                        <>
                                        </>

                                    )) : (
                                        <></>
                                    )
                                }
                            </table>

                        ) : (
                            <div className='w-[100%] h-[80vh] flex justify-center items-center'>
                                <h1 className='text-[30px] capitalize'>Campus Ambassdor Application not available</h1>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
}

export default CampusAmbassdorApplicationShow;
