import React from 'react'
import AdminPanelShowBoxTotalNumber from './AdminPanelShowBoxTotalNumber.tsx'
import HalfLoader from '../HalfLoader.tsx';
import Data_Fetch_Api from '../contexts/Data_Fetch_Api.tsx';

function Adimin_Panel_Box_Outline() {
    const [Isloading, Error, getdata] = Data_Fetch_Api('/api/participant/data/total/count');
    const [IsloadingIdRequest, ErrorIdRequest, getdataIdRequest] = Data_Fetch_Api('/api/v1/admin/auth/site-id/request/pending/data');
    const [IsEventListLoader, IsEventListError, EventDataList] = Data_Fetch_Api('/api/v1/admin/auth/event/data/list/')
    if (Isloading || IsloadingIdRequest || IsEventListLoader) {
        return <HalfLoader message="Loading.." />
    }
    if (Error || ErrorIdRequest || IsEventListError) {
        return <h1>Some thing want wrong</h1>
    }

    return (
        <>
            <div className='w-[100%] h-[auto]'>
                <AdminPanelShowBoxTotalNumber link='api/user/site-id/request/verify' totalnumber={Object(getdataIdRequest).data ? Object(getdataIdRequest).data.length : ""} text='Request For TechKshitiz Id ' icon="diversity_3" />
                {
                    Object(EventDataList)?.data?.length ? (
                        Object(EventDataList)?.data?.map((EventData, index) => (
                            <AdminPanelShowBoxTotalNumber key={index} link={`api/event/data/list/${EventData._id}`} state={EventData} totalnumber={Object(EventData)?.EventRegisteredParticipantLength} text={`${EventData.event_Name} Event Register`} icon="app_registration" />
                        ))
                    ) : (
                        <>
                            <div className='w-[250px] hover:scale-105 transition ease-in-out delay-200  flex h-[150px] bg-[#5a2ce5] rounded-[20px] justify-center items-center  gap-6 float-left m-[20px] text-[#fff] cursor-default'>
                                No Event Register List
                            </div>
                        </>
                    )
                }

                {/* <Admin_Panel_Show_Box_Total_Number link='api/team-register/contest/participant/list' totalnumber={Object(getdata).totalbgmiregister} text='Bgmi Team Register' icon="how_to_reg" />
                <Admin_Panel_Show_Box_Total_Number link='api/hackathon/team-register/contest/participant/list' totalnumber={Object(getdata).Totalhackathonregister} text='Hackathon Team Reg' icon="diversity_4" />
                <Admin_Panel_Show_Box_Total_Number link='api/coding/contest/participant/list' totalnumber={Object(getdata).Totalregistercoding} text='Coding Register ' icon="recent_actors" />
                <Admin_Panel_Show_Box_Total_Number link='api/quiz/contest/participant/list' totalnumber={Object(getdata).Totalquizregister} text='Quiz Register' icon="switch_account" /> */}


                {/* <Admin_Panel_Show_Box_Total_Number link='api/quiz/contest/participant/list' totalnumber={getdataes2.length} text='Quiz Id Allot' icon="personal_injury" />
                <Admin_Panel_Show_Box_Total_Number link='api/quiz/contest/participant/list' totalnumber={getdataes2.length} text='Coding Id Allot' icon="how_to_reg" /> */}


                <AdminPanelShowBoxTotalNumber link='api/all-question-list/contest/mcq-question/numerical-question' totalnumber={Number(Object(getdata).numbericalquestion) + Number(Object(getdata).mcqquestion)} text='Total Questions' icon="quiz" />
                <AdminPanelShowBoxTotalNumber totalnumber={Object(getdata).mcqAnswer} text='Mcq Answer' link='api/answer/mcq-question' icon="receipt_long" />
                <AdminPanelShowBoxTotalNumber totalnumber={Object(getdata).NumericalAnswer} text='Numerical Answer' link='api/answer/numerical-question' icon="receipt_long" />
            </div>

        </>
    )
}

export default Adimin_Panel_Box_Outline
